import React, { Component, createRef } from "react";
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Element, scroller } from 'react-scroll'
import { Icon, notification, Divider } from 'antd';


import * as userActions from '../../actions/user';
import * as teamsActions from '../../actions/teams';
import { StoreState, ApplicationState, UserState, Team, Coupon, MatchesState } from '../../types';
import PlayerPortrait from '../PlayerPortrait/PlayerPortrait';
import { ContainerStyle, TableListStyle, BasicTableStyle } from '../PlayerList/PlayerListStyle';
import Select from '../UI/Select/Select';
import * as matchesActions from '../../actions/matches'
import Link from '../UI/Link/Link';

import { Layout, Title, TitleType, PlayerList, PlayerType, Block, Button, Row, Col, Checkbox, Input } from '..';

export interface Props {
	application: ApplicationState;
	user: UserState;
	fetchUserTeamsAndLeagues: any;
	matches: MatchesState;
}

export interface State {
	teamsToPay: Team[],
	teamIdsSelectedForPayment: number[],
	newPaymentResult: any,
	isCreatingAPayment: boolean,
	isInProcessOfPaymentCancel: boolean,
	validatedCoupons: Coupon[],
	couponSlots: string[],
	promotions: [],
	activePromo: {message: '', weekId: 0, competitionFeed: ''},
	paymentMethod: string,
}

class Payments extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			teamsToPay: props.user.teams
				.filter(team => !team.paid && !team.paymentId),
			teamIdsSelectedForPayment: props.user.teams
				.filter(team => !team.paid && !team.paymentId)
				.map(team => team.id),
			newPaymentResult: null,
			isCreatingAPayment: false,
			validatedCoupons: [],
			couponSlots: [],
			isInProcessOfPaymentCancel: false,
			promotions: [],
			activePromo: {message: '', weekId: 0, competitionFeed: ''},
			paymentMethod: 'BANCONTACT'
		};
	};

	componentDidMount() {		
		matchesActions.getPromotions(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId
		)
			.then(result => {
				this.setState({ promotions: result });
			})
	}

	paymentIdChangedInTeams(teams1: Team[], teams2: Team[]) {
		return !!teams1.find(team1 => {
			const team2 = teams2.find(item => item.id === team1.id);
			return team2 && team1.paymentId != team2.paymentId;
		})
	}

	onPaymentMethod = (value: string) => {
		this.setState({ paymentMethod: value });
	};

	componentDidUpdate(prevProps: Props, prevState: State) {
		const teamsLengthChanged = (this.props.user.teams.length !== prevProps.user.teams.length)
		
		if (this.props.user.teams.length && (teamsLengthChanged || this.paymentIdChangedInTeams(this.props.user.teams, prevProps.user.teams))) {
			this.setState({
				teamsToPay: this.props.user.teams
					.filter(team => !team.paid && !team.paymentId),
				teamIdsSelectedForPayment: this.props.user.teams
					.filter(team => !team.paid && !team.paymentId)
					.map(team => team.id),
			});
		}
		if (this.state.newPaymentResult !== prevState.newPaymentResult) {
			if(this.state.newPaymentResult && this.state.newPaymentResult.provider && this.state.newPaymentResult.provider.mollie) {
				window.location.href = this.state.newPaymentResult.provider.mollie._links.checkout.href;
			} else if(this.state.newPaymentResult && this.state.newPaymentResult.order && this.state.newPaymentResult.order.amount === 0) {
				this.setState({validatedCoupons: [], couponSlots: []})
				this.props.fetchUserTeamsAndLeagues(this.props.application.competition.competitionFeed)
			}
		}

		if(prevProps.matches.info.deadlineWeek !== this.props.matches.info.deadlineWeek) {
			this.setActivePromo();
		}

		if(prevState.promotions.length !== this.state.promotions.length) {
			this.setActivePromo();
		}
	}

	setActivePromo() {
		const promo:any = this.state.promotions.find((promo: any) => { return promo.weekId === this.props.matches.info.deadlineWeek });

		if(promo) {
			this.setState({activePromo: promo});
		}
	}

	onTeamPayToggle = (teamId: number, status: boolean) => {
		if (status) {
			this.setState({ teamIdsSelectedForPayment: [...this.state.teamIdsSelectedForPayment, teamId] });
		} else {
			this.setState({ teamIdsSelectedForPayment: this.state.teamIdsSelectedForPayment.filter(id => id !== teamId), validatedCoupons: [], couponSlots: [] });
		}
	}

	initiatePayment = () => {
		const { teamIdsSelectedForPayment, validatedCoupons } = this.state;
		const redirectURL = `https://${window.location.hostname}/my-teams`;

		this.setState({ isCreatingAPayment: true });

		const couponCodes = validatedCoupons.map((coupon: Coupon) => coupon.code);

		teamsActions.createPayment(
			couponCodes,
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId,
			teamIdsSelectedForPayment,
			redirectURL,
			this.state.paymentMethod
		)
			.then((paymentResult: any) => {
				if (paymentResult && paymentResult.order && paymentResult.order.amount && (window as any).fbq) {
					(window as any).fbq('track', 'Purchase', { value: (paymentResult.order.amount / 100), currency: 'EUR' });
				}
				this.setState({ newPaymentResult: paymentResult, isCreatingAPayment: false });
			})
			.catch(() => {
				notification.warning({ message: 'Betaling mislukt. Contacteer ons via de chat.' });
			});
	};

	cancelPayment = (paymentId: number) => {
		this.setState({ isInProcessOfPaymentCancel: true });

		return teamsActions
			.cancelPayment(paymentId)
			.then(() => this.props.fetchUserTeamsAndLeagues(this.props.application.competition.competitionFeed))
			.then(() => {
				this.setState({ isInProcessOfPaymentCancel: false });
			})
	};

	addCoupon = () => {
		if ((this.state.couponSlots.length - this.state.validatedCoupons.length) === 1) {
			notification.warning({ message: 'Kortingscode ongeldig.' });
			return;
		}

		const alreadyAddedADiscountCoupon = !!this.state.validatedCoupons.find((coupon: Coupon) => coupon.type === 'discount1+1');

		if (alreadyAddedADiscountCoupon) {
			notification.warning({ message: 'Je kan slechts één 1+1-kortingscode per account gebruiken.' });
			return;
		}

		// const teamOfTwoGroups = Math.floor(this.state.teamIdsSelectedForPayment.length / 2);

		// if ((this.state.validatedCoupons.length === teamOfTwoGroups)) {
		// 	notification.warning({ message: 'Je moet minstens twee teams samen afrekenen (waarvan één betaald) wanneer je de 1+1-kortingscode wilt gebruiken.' });
		// 	return;
		// }

		const slots = this.state.couponSlots.concat(['']);
		this.setState({ couponSlots: slots });
	};

	removeCoupon = (removableIndex: number) => {
		const removeHandler = (item: any, index: number) => index !== removableIndex;
		const validatedCoupons = this.state.validatedCoupons.filter(removeHandler);
		const couponSlots = this.state.couponSlots.filter(removeHandler);

		this.setState({ validatedCoupons, couponSlots });
	};

	onCouponSlotValueChange = (value: string, couponIndex: number) => {
		this.setState({
			couponSlots: this.state.couponSlots
				.map((couponSlotValue: string, couponSlotsIndex: number) => {
					if (couponIndex === couponSlotsIndex) {
						return value;
					}
					return couponSlotValue;
				})
		});

		if (value.length > 5 && value.length < 12) {
			teamsActions.validateCoupon(value, this.props.application.competition.competitionFeed)
				.then(validateResult => {
					if (validateResult && validateResult.coupon && validateResult.coupon.id) {

						const validatedBefore = this.state.validatedCoupons
							.find((coupon: Coupon) => coupon.id === validateResult.coupon.id);

						if (validatedBefore) {
							return;
						}

						const validatedCoupons = [
							...this.state.validatedCoupons,
							{
								id: validateResult.coupon.id,
								code: validateResult.coupon.code,
								competitionFeed: validateResult.coupon.competitionFeed,
								type: validateResult.coupon.type,
								value: validateResult.coupon.value,
							}
						];

						this.setState({ validatedCoupons })
					}
				});
		}
	};

	getActivePaymentsList = () => {
		const userTeams = this.props.user && this.props.user.teams && this.props.user.teams || [];

		const pendingPaymentTeams = userTeams
			.filter((team: any) => !parseInt(team.paid) && team.paymentId);

		const activePaymentsMap: any = {};
		let activePaymentList: ({ paymentId: number } & { teams: any[] })[] = [];

		pendingPaymentTeams.forEach((team: any) => {
			const hasActivePayment = activePaymentsMap[team.paymentId];
			if (hasActivePayment) {
				activePaymentsMap[team.paymentId].push(team);
			} else {
				activePaymentsMap[team.paymentId] = [team];
			}
		});

		activePaymentList = Object
			.keys(activePaymentsMap)
			.map((paymentId: string) => Object.assign({}, { paymentId: parseInt(paymentId) }, { teams: activePaymentsMap[paymentId] }));

		return activePaymentList;
	}

	render() {
		const { teamsToPay, teamIdsSelectedForPayment, isCreatingAPayment, newPaymentResult, couponSlots, validatedCoupons, isInProcessOfPaymentCancel } = this.state;
		const { application, user } = this.props;
		const activePaymentsList = this.getActivePaymentsList();

		const teamPrice = (teamsToPay && teamsToPay.length && teamsToPay[0].price) || application.competition.teamPrice;

		const totalTeamsSelectedForPayment = teamIdsSelectedForPayment.length;
		const totalValidatedCoupons = validatedCoupons.length;

		const teamOfTwoGroups = Math.floor(totalTeamsSelectedForPayment / 2);
		const notInGroupTeam = totalTeamsSelectedForPayment % 2;
		const deadlineWeek = (this.props.matches && this.props.matches.info && this.props.matches.info.deadlineWeek) || 0;
		const promoFactor = (this.state.activePromo.weekId === deadlineWeek) ? 1 : 2;
		const amountToPay = (notInGroupTeam * teamPrice) + (teamOfTwoGroups * (teamPrice * promoFactor)) - (totalValidatedCoupons * teamPrice);

		const columns = [{
			key: 'name',
			title: 'Team',
			dataIndex: 'name',
			width: '70%',
			ellipsis: true,
			render: (text: string, record: any) => {
				return <b>
					<Link to={`team/${record.id}`}>{record.name}</Link>
				</b>
			}
		}, {
			key: 'pay',
			title: 'Betaal',
			width: '30%',
			dataIndex: 'paid',
			ellipsis: true,
			render: (text: string, record: any) => {
				const checked = teamIdsSelectedForPayment.includes(record.id);
				return <Checkbox checked={checked} disabled={!deadlineWeek} onChange={(e: any) => this.onTeamPayToggle(record.id, e.target.checked)} />;
			}
		}
		];

		const couponActionButtonsProps = { fontSize: '20px', marginLeft: '5px' };

		return (
			<Layout>
				<Row>
					<Col md={24} sm={24} xs={24}>
						<Block>
							<Title type={TitleType.h2}>BETALINGEN</Title>
							<BasicTableStyle
								columns={columns}
								dataSource={teamsToPay}
								showHeader={true}
								locale={{ emptyText: 'Je hebt geen teams om te betalen. Controleer hieronder of er geen teams gekoppeld zijn aan een niet-afgeronde betaling. Lees de tekst en probeer desgevallend opnieuw.' }}
								loading={false}
								pagination={false}
								rowKey={(record: any, index: number) => `record-${index + 1}`}
								rowClassName={(record: object, index: number) => index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'}
							/>
							{
								teamsToPay && teamsToPay.length ?
									<React.Fragment>
										<Divider />
										<p style={{ textAlign: 'right' }}>
											<span>Totaal te betalen: <b> €{amountToPay/100} </b> </span>
										</p>
									</React.Fragment> : null
							}

						</Block>
					</Col>
				</Row>
				<Block>
					<Row>
						<Col md={12} sm={24} xs={24}>
								{
									couponSlots.map((coupon, index) => {
										const couponIsValid = validatedCoupons
											.find((validatedCoupon: Coupon, validatedCouponIndex: number) =>
												validatedCoupon.code === coupon && validatedCouponIndex === index
											);

										const takeCouponInConsideration = coupon && coupon.length > 5;

										return <Row key={`index-${index}`}>
											<Col md={18} sm={18} xs={18}>
												<Input
													onChange={(e: any) => this.onCouponSlotValueChange(e.target.value, index)}
													disabled={!!couponIsValid}
													value={coupon}
												/>
											</Col>
											{
												<Col md={6} sm={6} xs={6} style={{ height: '20px', paddingTop: '10px' }}>
													{
														couponIsValid &&
														<Icon
															type="check-circle"
															style={{ ...couponActionButtonsProps, color: 'green' }}
															theme="filled" />
													}
													{
														!couponIsValid && takeCouponInConsideration &&
														<Icon
															type="warning"
															style={{ ...couponActionButtonsProps, color: 'red' }}
															theme="filled" />
													}
													<Icon
														type="delete"
														style={{ ...couponActionButtonsProps }}
														onClick={() => this.removeCoupon(index)}
														theme="filled" />
												</Col>
											}
										</Row>
									})
								}
								{
									teamsToPay && teamsToPay.length && deadlineWeek ?
										<Row><Col md={24} sm={24} xs={24}>
											<Button
												onClick={this.addCoupon}
												type="default"
												style={{ display: 'block', width: '220px', margin: '5px auto 20px' }}>
												<Icon type="tag" theme="filled" />
												Kortingscode toevoegen
							</Button>
										</Col></Row> : null
								}
						</Col>
						<Col md={12} sm={24} xs={24}>
							{
								!newPaymentResult && teamIdsSelectedForPayment.length && deadlineWeek ?
									<Row>
										{/* <Col md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
											<Select
												block
												values={[
													{value: 'VISA', text: 'VISA'},
													{value: 'MASTERCARD', text: 'MASTERCARD'},
													{value: 'AMERICAN_EXPRESS', text: 'AMERICAN EXPRESS'},
													{value: 'BANCONTACT', text: 'BANCONTACT'},
													{value: 'MAESTRO', text: 'MAESTRO'},
													{value: 'IDEAL', text: 'IDEAL'},
													{value: 'BELFIUS_ONLINE', text: 'BELFIUS ONLINE'},
													{value: 'KBC_ONLINE', text: 'KBC ONLINE'},
													{value: 'BANK_TRANSFER', text: 'OVERSCHRIJVING'},
													// {value: 'DIRECT_DEBIT', text: 'DIRECT DEBIT'},
													// {value: 'CREDIT_TRANSFER', text: 'CREDIT TRANSFER'},
												]}
												style={{marginRight: 0}}
												keyProperty={'value'}
												onSelect={(value: any) => this.onPaymentMethod(value)}
												textProperty={'text'}
												value={this.state.paymentMethod}
												placeholder="Betalingsmethode"
											/>
										</Col> */}
										<Col md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
											<Button
												onClick={this.initiatePayment}
												loading={isCreatingAPayment}
												type="primary"
												style={{ margin: '5px auto 20px', width: '220px' }}>
												<Icon type="credit-card" theme="filled" />
												Betaal
											</Button>
										</Col>
									</Row> : null
							}
						</Col>
					</Row>
				</Block>
				{
					activePaymentsList.length ?
					<Row>
						<Col md={24} sm={24} xs={24}>
							<Block>
								<h3>Betalingen in afwachting</h3>
								<TableListStyle
									columns={[{
										key: 'name',
										title: 'Team',
										dataIndex: 'name',
										width: '100%',
										ellipsis: true,
										render: (text: string, record: any) => {
											return <b>
												<Link to={`team/${record.id}`}>{record.name}</Link>
											</b>
										}
									}]}
									dataSource={activePaymentsList.map(payments => { return payments.teams }).flat()}
									showHeader={true}
									locale={{ emptyText: 'Je hebt geen teams om te betalen. Controleer hieronder of er geen teams gekoppeld zijn aan een niet-afgeronde betaling. Lees de tekst en probeer desgevallend opnieuw.' }}
									loading={false}
									pagination={false}
									rowKey={(record: any, index: number) => `record-${index + 1}`}
									rowClassName={(record: object, index: number) => index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'}
								/>

								<div className="alert alert-info">
									<p>Waarom zie ik enkel "Betalingen in afwachting"?</p>
									<p>Je ploegen worden in "afwachting" gezet van zodra je tijdens het afrekenen kiest om te betalen via overschrijving. Of wanneer je bij een andere betaalmethode de procedure vroegtijdig hebt afgebroken.</p>
									<p>Je hebt 2 opties:</p>
									<ul>
										<li>1. Koos je om te betalen via overschrijving, dan kan je de ploegen betalen volgens de betaalinstructies die je naar je e-mailadres liet sturen tijdens de afrekenprocedure.</li>
										<li>2. Je kan de betaling annuleren (je ploegen worden niet verwijderd) en opnieuw proberen met dezelfde of een andere betaalmethode. We raden aan om online te betalen, dan zijn je ploegen meteen actief.</li>
									</ul>
								</div>
								<div style={{ textAlign: 'center' }}>
									<Button
										onClick={(e: any) => this.cancelPayment(activePaymentsList[0].paymentId)}
										loading={isInProcessOfPaymentCancel}
										type="primary"
										style={{ margin: '0 auto', width: '150px' }}>
										Annuleer
										</Button>
								</div>
							</Block>
						</Col>
					</Row> :
						null
				}
			</Layout>
		);
	}
}

export const mapDispatchToProps = {
	fetchUserTeamsAndLeagues: userActions.fetchTeams,
};

export function mapStateToProps({ application, user, matches }: StoreState.All) {
	return {
		application,
		user,
		matches
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Payments);