import { notification } from 'antd';

import {
	SET_PROFILE_DATA,
	LOG_OUT,
	USER_TEAMS_LOADING_STARTED,
	USER_TEAMS_FETCH_FAILED,
	USER_TEAMS_FETCHED_WITH_SUCCESS,
	USER_LEAGUES_FETCH_FAILED,
	USER_LEAGUES_FETCHED_WITH_SUCCESS,
	USER_LEAGUES_LOADING_STARTED,
	USER_TEAM_SET_TEAM_NAME
} from './actions-types';
import { Team, User, League } from './../types'
import { Dispatch } from "redux";
import http from './../lib/http';
import lockr from 'lockr';

import { fetchTeamLeagues } from './leagues';

export interface SetProfileData {
	type: SET_PROFILE_DATA;
	data: User;
}

export interface LogOut {
	type: LOG_OUT;
}

export interface LoadTeams {
	type: USER_TEAMS_LOADING_STARTED
}

export interface LoadLeagues {
	type: USER_LEAGUES_LOADING_STARTED
}

export interface TeamsFetchedWithSuccess {
	type: USER_TEAMS_FETCHED_WITH_SUCCESS;
	teams: Team[];
	concat?: boolean
}

export interface TeamNameChanged {
	type: USER_TEAM_SET_TEAM_NAME;
	id: number;
	name: string;
}

export interface LeaguesFetchedWithSuccess {
	type: USER_LEAGUES_FETCHED_WITH_SUCCESS;
	leagues: League[];
}

export interface TeamsFetchFailed {
	type: USER_TEAMS_FETCH_FAILED;
}

export interface LeaguesFetchFailed {
	type: USER_LEAGUES_FETCH_FAILED;
}

export type UserAction = SetProfileData | LogOut | LoadTeams | TeamsFetchedWithSuccess | TeamsFetchFailed |
	LoadLeagues | LeaguesFetchedWithSuccess | LeaguesFetchFailed | TeamNameChanged;

export function setProfileData(data: User): SetProfileData {
	return {
		type: SET_PROFILE_DATA,
		data
	}
}

export function logOut(): LogOut {
	return {
		type: LOG_OUT
	}
}

export function loadTeams(): LoadTeams {
	return {
		type: USER_TEAMS_LOADING_STARTED
	}
}

export function teamsFetchedWithSuccess(teams: Team[], concat?: boolean): TeamsFetchedWithSuccess {
	return {
		type: USER_TEAMS_FETCHED_WITH_SUCCESS,
		teams,
		concat
	}
}

export function teamNameChanged(id: number, name: string): TeamNameChanged {
	return {
		type: USER_TEAM_SET_TEAM_NAME,
		id,
		name
	}
}

export function teamsFetchFailed(): TeamsFetchFailed {
	return {
		type: USER_TEAMS_FETCH_FAILED
	}
}

export function loadLeagues(): LoadLeagues {
	return {
		type: USER_LEAGUES_LOADING_STARTED
	}
}

export function leaguesFetchedWithSuccess(leagues: League[]): LeaguesFetchedWithSuccess {
	return {
		type: USER_LEAGUES_FETCHED_WITH_SUCCESS,
		leagues
	}
}

export function leaguesFetchFailed(): LeaguesFetchFailed {
	return {
		type: USER_LEAGUES_FETCH_FAILED
	}
}

export function fetchUserTeamLeaguesAndPersist(teamId: number) {
	return function (dispatch: Dispatch<UserAction>) {
		dispatch(loadLeagues());

		return fetchTeamLeagues(teamId)
			.then((result: any) => {
				dispatch(leaguesFetchedWithSuccess(result.leagues));
				return result;
			})
			.catch((error: any) => {
				dispatch(leaguesFetchFailed());
			});
	};
}

export function fetchUserTeamLeagues(teamId: number) {
	return fetchTeamLeagues(teamId)
}

export function fetchTeams(competitionFeed: string) {
	return function (dispatch: Dispatch<UserAction>) {
		dispatch(loadTeams());

		return http.get(`teams`, { competitionFeed })
			.then((result: any) => {
				dispatch(teamsFetchedWithSuccess(result.teams));
				return result.teams;
			})
			.catch((error: any) => {
				dispatch(teamsFetchFailed());
			});
	};
}

export function vrtState(userAgent: string) {
	return http.post(`user/auth/vrt/state`, { userAgent });
}

export function vrtLogin(code: string, redirectURI: string, state: string, userAgent: string) {
	return http.post(`user/auth/vrt`, { code, redirectURI, state, userAgent });
}

export function ssoLogin(token: string) {
	return http.post(`user/auth/dpgm`, { token });
}

export function register(firstName: string, lastName: string, email: string, password: string, dob: string, street: string, street_nr: string, post_code: string, city: string, commercialAccepted: boolean) {
	return http.post(`user/register`, { firstName, lastName, email, password, dob, street, street_nr, post_code, city, commercialAccepted: commercialAccepted ? 1 : 0 })
		.then((result) => {
			notification.success({ message: 'Registratie gelukt. Je kan inloggen!' });
			return result;
		})
		.catch(error => {
			if(error && error.response && error.response.status === 409) {
				notification.warning({ message: 'Dit email adres is reeds in gebruik.' });
			} else {
				notification.warning({ message: 'Registratie mislukt. Contacteer ons via de chat.' });
			}
		})
}

export function login(email: string, password: string) {
	return http.post(`user/login`, { email, password });
}

export function forgot(email: string) {
	return http.post(`user/forgot`, { email });
}

export function passwordReset(password: string, resetToken: string) {
	return http.post(`user/reset`, { password, resetToken });
}

export function updatePassword(newPassword: string) {
	return http.put(`user`, { newPassword });
}

export function updateProfile(firstName: string, lastName: string, city: string, street: string, streetNr: string, postCode: string) {
	return http.put(`user`, { firstName, lastName, city, street, streetNr, postCode });
}

export function logOutUser() {
	return function (dispatch: Dispatch<UserAction>) {
		dispatch(logOut());
		lockr.rm('token');
		lockr.rm('user');
	};
}

export function getRules(competitionFeed: string) {
	return http.get(`rules?competitionFeed=${competitionFeed}`, { });
}

export function getPrizes(competitionFeed: string) {
	return http.get(`prizes?competitionFeed=${competitionFeed}`, { });
}

export function getPage(competitionFeed: string, slug: string) {
	return http.get(`pages?competitionFeed=${competitionFeed}&slug=${slug}`, {});
}

export function facebookLogin(accessToken: string, favoriteClubId?: number, logInOnly?: boolean) {
	let url = `user/auth/facebook?access_token=${accessToken}`;

	if (favoriteClubId) {
		url += `&favoriteClubId=${favoriteClubId}`;
	}

	if (logInOnly) {
		url += `&logInOnly=${logInOnly}`;
	}
	return http.get(url, {});
}

