import React, { Component } from "react";
import { Form } from 'antd';
import { Layout, Row, Col, RegisterForm } from '../../components';
import { RegisterStyle } from "./RegisterStyles";
import { PageStyle } from "../../styles/global";

export interface Props {}

const WrappedRegisterForm = Form.create()(RegisterForm);

class Register extends Component<Props> {
	render() {

		return (
			<Layout>
				<RegisterStyle>
					<PageStyle>
						<Row gutter={32} style={{ backgroundColor: 'rgb(29, 29, 29)', padding: '40px' }}>
							<Col className="gutter-row" md={{ span: 10, offset: 7 }} sm={24} xs={24}>
								<div className="gutter-box" style={{ background: '#FFF', padding: '20px', textAlign: 'center' }}>
									<WrappedRegisterForm />
								</div>
								<div style={{marginTop: "20px"}}>Uw persoonsgegevens worden verwerkt door DPG Media, KBO 0403.506.340 (verwerkingsverantwoordelijke) in het kader van deze race, in het bijzonder om de winnaar te selecteren, op de hoogte te brengen en de prijs af te leveren. In ons gerechtvaardigd belang verwerken wij uw persoonsgegevens ook voor analysedoeleinden. Uw persoonsgegevens worden niet doorgegeven aan derden. Wij bewaren uw gegevens na afloop van de race, en voor zover u hierboven heeft ingestemd met andere doeleinden, niet langer dan nodig voor de verwerking van het doel waarvoor deze zijn verzameld. U kan uw recht op inzage, verbetering, beperking, bezwaar en overdraagbaarheid uitoefenen, en de DPO bereiken via privacy@pdpgmedia.be. U kan tevens bij de Gegevensbeschermingsautoriteit klacht indienen.</div>
							</Col>
						</Row>
					</PageStyle>
				</RegisterStyle>
			</Layout>
		);
	}
}

export default Register;
