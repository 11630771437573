const FootballMaxPositionsPicks = {
	Goalkeeper: { min: 1, max: 1 },
	Defender: { min: 3, max: 5 },
	Midfielder: { min: 3, max: 5 },
	Forward: { min: 1, max: 3 },
	Bench: { min: 4, max: 4 }
};

const FootballPositionIds =  {
	Goalkeeper: 1,
	Defender: 2,
	Midfielder: 3,
	Forward: 4
};

const CyclocrossMaxPositionsPicks = {
	MenElite: { min: 9, max: 9 },
	MenJunior: { min: 1, max: 1 },
	WomenElite: { min: 3, max: 3 }
};

const CyclocrossPositionIds =  {
	MenElite: 10,
	MenJunior: 8,
	WomenElite: 9
};

const FormulaOneMaxPositionsPicks = {
	Driver: { min: 6, max: 6 },
	Team: { min: 1, max: 1 },
	Motor: { min: 1, max: 1 }
};

const FormulaOnePositionIds =  {
	Driver: 30,
	Team: 31,
	Motor: 32
};

export {
	FootballMaxPositionsPicks,
	FootballPositionIds,
	CyclocrossMaxPositionsPicks,
	CyclocrossPositionIds,
	FormulaOneMaxPositionsPicks,
	FormulaOnePositionIds
}