import React, { Component } from "react";
import { connect } from 'react-redux';
import { StoreState, MatchesState } from './../../types';
import { Layout, Calendar, Row, Col, TitleType, Title, Block } from './../../components';

export interface Props {
	matches: MatchesState;
	t?: any;
}

class Deadlines extends Component<Props> {
	render() {
		const { matches, t } = this.props;
		return (
			<Layout>
				<Row>
					<Col md={24} sm={24}>
						<Block>
							<Title type={TitleType.h2}>DEADLINES</Title>
							<Calendar
								t={t}
								data={matches.data}
								size={30}
								showHeader={true}
								isLoading={matches.isFetching} />
						</Block>
					</Col>
				</Row>
			</Layout>
		);
	}
}

export function mapStateToProps({ user, matches }: StoreState.All) {
	return {
		matches
	}
}

export default connect(mapStateToProps, {})(Deadlines);
