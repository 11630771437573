import React, { Component } from "react";
import { connect } from 'react-redux';

import * as userActions from '../../actions/user'
import { Button } from '../../components';

import { LoginStyle } from "./LoginStyles";
import { Redirect } from "react-router";
import { StoreState, UserState, ApplicationState } from './../../types';

export interface Props {
	onSetProfileData: any;
	fetchUserTeams: any;
	user: UserState;
	application: ApplicationState;
	t: any;
}

class Login extends Component<Props> {

	login = () => {

		const environment = 'https://login.persgroep.net/authorize';
		const id = 'hln-gouden-f1';
		const redirect = `${window.location.origin}/api/auth/callback/dpgmedia`;

		const vrtLoginURL = `${environment}?prompt=login&response_type=token&client_id=${id}&redirect_uri=${redirect}&state=${redirect}&scope=openid%20profile%20email`;

		window.location.replace(vrtLoginURL);
	};

	render() {
		return (
			<LoginStyle>
				{this.props.user.authenticated && this.props.user.fetched && <Redirect
					to={{ pathname: `/new` }}
				/>}
				<Button type="primary" className="playNow" onClick={this.login}>{this.props.t('user.loginButton')}</Button>
			</LoginStyle>
		);
	}
}

export const mapDispatchToProps = {
	onSetProfileData: (data: any) => (dispatch: any) => dispatch(userActions.setProfileData(data)),
	fetchUserTeams: userActions.fetchTeams
};

export function mapStateToProps({ user, application }: StoreState.All) {
	return {
		user,
		application
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);