function FormulaOnePicker(maxPositionsPicks, positionsIds) {

    var result = {
      Driver: [],
      Team: [],
      Motor: []
    };
    
    function pickAtPosition(positionName, playerId) {
      result[positionName].push(playerId);
    }
    
    function set(starting) {
      result.Driver = [];
      result.Team = [];
      result.Motor = [];
    
      starting.forEach(function (player) {
        result[getPositionNameById(player.Player.position_id)].push(player.Player.id)
      });
    
      return {
        result: result
      };
    }
    
    function canPick(player) {
      return checkAndPick(player, true);
    }
    
    function checkAndPick(player, justCheck) {
      var maxAllowedPlayersForPositions = getMaxAllowedPlayersForPositions();
      var pickedPositionName = getPositionNameById(player.Player.position_id);
    
      if (result[pickedPositionName].indexOf(player.Player.id) !== -1) {
        return false;
      }

      if (result[pickedPositionName] && (result[pickedPositionName].length < maxAllowedPlayersForPositions[pickedPositionName])) {
        if (!justCheck) {
          pickAtPosition(pickedPositionName, player.Player.id);
        }
        return true;
      } else {
        return false;
      }
    }
    
    function isValid() {
      var fieldPlayers = 8;
      var validated = (result.Driver.length >= maxPositionsPicks.Driver.min && result.Driver.length <= maxPositionsPicks.Driver.max) 
        && (result.Team.length >= maxPositionsPicks.Team.min && result.Team.length <= maxPositionsPicks.Team.max)
        && (result.Motor.length >= maxPositionsPicks.Motor.min && result.Motor.length <= maxPositionsPicks.Motor.max)
        && (fieldPlayers === (result.Driver.length + result.Team.length + result.Motor.length));
    
      return validated;
    }
    
    function getMaxAllowedPlayersForPositions() {
      var maxPlayersForEachPosition = {
        Driver: 6,
        Team: 1,
        Motor: 1
      };
    
      return maxPlayersForEachPosition;
    }
    
    function pick(player) {
      var picked = checkAndPick(player);
    
      return {
        result: result,
        picked: picked
      };
    }
    
    function getPositionNameById(positionId) {
      return Object.keys(positionsIds)
          .find(function (positionName) {
            return positionsIds[positionName] === positionId
          })
    }
    
    function remove(player) {
      var removeId = player.Player.id;
      var positionId = player.Player.position_id;
      var removed = false;
    
      result[getPositionNameById(positionId)] = result[getPositionNameById(positionId)]
          .filter(removeHandler);
    
      function removeHandler(playerId) {
        if (playerId === removeId) {
          removed = true;
          return false;
        } else {
          return true;
        }
      }
    
      return {
        result: result,
        removed: removed
      };
    }
    
    return {
      pick: pick,
      remove: remove,
      set: set,
      isValid: isValid,
      canPick: canPick,
    };
  }
    
  exports.default = FormulaOnePicker;