import React from 'react';

function CaptainButtonSvg() {
	return <svg width="1em" height="1em" viewBox="0 0 48 48" version="1.1">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Icon/Captain" fill="#FFD700">
						<g id="Group-5" fill="none">
								<rect id="Rectangle" fill="#E05C2A" x="0" y="0" width="48" height="48"></rect>
								<text id="C" fontFamily="Helvetica" fontSize="32" fontWeight="normal" fill="#FFFFFF">
										<tspan x="12.4453125" y="31">C</tspan>
								</text>
						</g>
				</g>
		</g>
	</svg>;
}

function SwapButtonSvg() {
	return <svg width="1em" height="1em" viewBox="0 0 48 48" version="1.1">
	<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Icon/Sub" transform="translate(4.000000, 0.000000)">
					<g id="Group-6">
							<rect id="Rectangle" fill="#FFD700" x="0" y="0" width="40" height="40"></rect>
							<g id="code_SUBSTITUTION" transform="translate(8.000000, 6.400000)" fill="#FFFFFF" fillRule="nonzero">
									<path d="M8,24.9628395 L12.2260168,16.6848701 L14.6853397,20.1151299 C16.606405,19.8170533 19.3720202,19.145588 19.7559065,17.5053736 C20.3709414,14.521436 15.5298897,9.6 15.5298897,9.6 C15.5298897,9.6 24.4434037,14.2978785 23.9827401,18.2505653 C23.598037,21.4572677 19.9102778,23.0974821 17.3741776,23.9179857 L19.6791292,27.2 L8,24.9628395 Z" id="Path"></path>
									<path d="M16,2.38699158 L11.565726,10.5159227 L9.27261234,7.01035089 C7.36141343,7.23390838 4.61000207,7.83085447 4.15089179,9.54638079 C3.46263266,12.5295257 8.20243846,17.6 8.20243846,17.6 C8.20243846,17.6 -0.512531066,12.6777713 0.0237747511,8.72587721 C0.482072449,5.51917481 4.22808732,3.95268682 6.74953724,3.28201432 L4.53280654,0 L16,2.38699158 Z" id="Path"></path>
							</g>
					</g>
			</g>
	</g>
</svg>;
}

function DeleteButtonSvg() {
	return <svg width="1em" height="1em" viewBox="0 0 48 48" version="1.1">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Icon/Delete">
						<rect id="Rectangle" fill="#FFD700" fillRule="evenodd" x="0" y="0" width="48" height="48"></rect>
						<path d="M33.6,33.6 L14.4,14.4 M14.4,33.6 L33.6,14.4" id="Line-2" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="square"></path>
				</g>
		</g>
	</svg>;
}

function AddButtonSvg() {
	return <svg width="33px" height="37px" viewBox="0 0 33 37" version="1.1">
	<defs>
			<path d="M19.8333333,13.1666667 L29,13.1666667 L29,19.4166667 L19.8333333,19.4166667 L19.8333333,29 L13.5833333,29 L13.5833333,19.4166667 L4,19.4166667 L4,13.1666667 L13.5833333,13.1666667 L13.5833333,4 L19.8333333,4 L19.8333333,13.1666667 Z" id="path-1"></path>
			<filter x="-32.0%" y="-32.0%" width="164.0%" height="180.0%" filterUnits="objectBoundingBox" id="filter-2">
					<feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
					<feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
					<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
					<feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
					<feGaussianBlur stdDeviation="2" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>
					<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.12 0" type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"></feColorMatrix>
					<feMerge>
							<feMergeNode in="shadowMatrixOuter1"></feMergeNode>
							<feMergeNode in="shadowMatrixOuter2"></feMergeNode>
					</feMerge>
			</filter>
	</defs>
	<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Combined-Shape">
					<use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
					<use fill="#FFD700" fillRule="evenodd" xlinkHref="#path-1"></use>
			</g>
	</g>
</svg>;
}

function BikeSvg() {
	return <svg width="23px" height="14px" viewBox="0 0 23 14" version="1.1">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="download" fill="#FFD700" fillRule="nonzero">
						<path d="M4.5,14 C2.01863238,14 0,11.9812503 0,9.49999891 C0,7.01874756 2.01863238,5 4.5,5 C6.98136762,5 9,7.01874974 9,9.50000109 C9,11.9812524 6.9813698,14 4.5,14 Z M4.5,5.73459756 C2.42347201,5.73459756 0.734202985,7.42378595 0.734202985,9.49999891 C0.734202985,11.5762119 2.42347201,13.2654024 4.5,13.2654024 C6.57652799,13.2654024 8.26579702,11.576214 8.26579702,9.49999891 C8.26579702,7.42378377 6.57652799,5.73459756 4.5,5.73459756 Z" id="Shape"></path>
						<path d="M18.5,14 C16.0186302,14 14,11.9812503 14,9.49999891 C14,7.01874756 16.0186302,5 18.5,5 C20.9813698,5 23,7.01874974 23,9.49999891 C23,11.9812481 20.9813676,14 18.5,14 Z M18.5,5.73459756 C16.423472,5.73459756 14.734203,7.42378595 14.734203,9.49999891 C14.734203,11.5762119 16.423472,13.2654024 18.5,13.2654024 C20.576528,13.2654024 22.265797,11.576214 22.265797,9.49999891 C22.265797,7.42378377 20.576528,5.73459756 18.5,5.73459756 Z" id="Shape"></path>
						<path d="M16.5960163,4 L8.40398605,4 C8.18075473,4 8,3.77612516 8,3.5 C8,3.22387484 8.18075473,3 8.40398605,3 L16.5960163,3 C16.8192453,3 17,3.22387484 17,3.5 C17,3.77612516 16.8192453,4 16.5960163,4 Z" id="Path"></path>
						<path d="M4.4211402,10 C4.34437182,10 4.26662245,9.98032772 4.19723661,9.93889988 C3.99990516,9.82225268 3.94085488,9.57761914 4.06486147,9.39223549 L8.22159757,3.18498211 C8.34609718,2.99982915 8.60690844,2.94451513 8.80276339,3.06092928 C9.00009484,3.17757647 9.05914512,3.42221001 8.93513853,3.60759366 L4.77839992,9.81484704 C4.69819054,9.93450262 4.56138587,10 4.4211402,10 Z" id="Path"></path>
						<path d="M12.3641614,9 C12.281645,9 12.1987046,8.97137609 12.1306504,8.9128193 C11.9762533,8.78040925 11.9558362,8.54442865 12.0847135,8.38579928 L16.3563921,3.13439361 C16.4852694,2.97554423 16.7153779,2.95456966 16.8693489,3.08719749 C17.0237481,3.21960753 17.0441631,3.45558591 16.9152858,3.6142175 L12.6436094,8.86562318 C12.5717287,8.95411507 12.4679451,9 12.3641614,9 Z" id="Path"></path>
						<path d="M10.6412586,9 C10.5026198,9 10.3702647,8.91244545 10.3116243,8.76599177 L7.02910915,0.539102447 C6.95120244,0.343003847 7.03497319,0.11576413 7.2171716,0.0315926862 C7.3985329,-0.0528036861 7.60963188,0.0376864113 7.68837571,0.23400994 L10.9708908,8.46089697 C11.0487976,8.65677064 10.9650268,8.88423529 10.7828284,8.96840673 C10.7367553,8.98984609 10.6885863,9 10.6412586,9 Z" id="Path"></path>
						<path d="M10.5863015,10 L4.41369855,10 C4.18510037,10 4,9.77612516 4,9.5 C4,9.22387484 4.18510037,9 4.41369855,9 L10.5863015,9 C10.8148996,9 11,9.22387484 11,9.5 C11,9.77612516 10.8148996,10 10.5863015,10 Z" id="Path"></path>
						<path d="M9.62190538,1 L6.37809238,1 C6.16917142,1 6,0.776125163 6,0.5 C6,0.223874837 6.16916917,0 6.37809238,0 L9.62190762,0 C9.83083083,0 10,0.223874837 10,0.5 C10,0.776125163 9.83082858,1 9.62190538,1 Z" id="Path"></path>
						<path d="M18.5552337,10 C18.3641096,10 18.1880481,9.89481045 18.1298786,9.73184269 L16.0192146,3.81740574 C16.0062306,3.78190219 16,3.74485326 16,3.70780656 L16,1.37775648 C16,1.16914034 16.198913,1 16.4445697,1 C16.6902237,1 16.8891367,1.16914034 16.8891367,1.37775648 L16.8891367,3.65179354 L18.9805835,9.51264434 C19.0522547,9.71221918 18.9193,9.92325994 18.6840311,9.98368259 C18.6414446,9.9947089 18.5978202,10 18.5552337,10 Z" id="Path"></path>
						<path d="M18.556053,4 C18.3591123,4 18.1996457,3.83346718 18.1996457,3.62806714 C18.1996457,3.4226671 18.3591123,3.25613429 18.556053,3.25613429 C18.9524307,3.25613429 19.2871854,2.91286159 19.2871854,2.50640536 C19.2871854,2.08583768 18.9590921,1.74386571 18.556053,1.74386571 L16.3564073,1.74386571 C16.1594667,1.74386571 16,1.5773329 16,1.37193286 C16,1.16653282 16.1594667,1 16.3564073,1 L18.556053,1 C19.352137,1 20,1.67590548 20,2.50640536 C20,3.32995552 19.352137,4 18.556053,4 Z" id="Path"></path>
						<path d="M11.5002799,11 C10.6727521,11 10,10.326879 10,9.4998608 C10,8.67284256 10.6727521,8 11.5002799,8 C12.3272479,8 13,8.67284256 13,9.4998608 C13,10.326879 12.3272479,11 11.5002799,11 Z M11.5002799,8.9568966 C11.1957552,8.9568966 10.9566042,9.1954212 10.9566042,9.49986364 C10.9566042,9.80458448 11.1957552,10.0431091 11.5002799,10.0431091 C11.8048046,10.0431091 12.0433987,9.80458448 12.0433987,9.49986364 C12.0433958,9.1954212 11.8048046,8.9568966 11.5002799,8.9568966 Z" id="Shape"></path>
				</g>
		</g>
	</svg>;
}

function EuroSvg() {
	return <svg width="1em" height="1em" viewBox="0 0 10 14" version="1.1">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="download-(1)" fill="#FFD700" fillRule="nonzero">
						<path d="M4.31377882,12.4151057 C5.97482446,13.5710332 8.13895436,13.5710332 9.8,12.4151057 L9.07902832,11.2707696 C7.85445393,12.1271458 6.25544212,12.1263272 5.0316733,11.2686976 C4.21358031,10.6952826 3.59476824,9.86148952 3.26733961,8.89141802 L7.50496496,8.89141802 L7.50496496,7.52185275 L2.97160186,7.52185275 C2.95510246,7.34356137 2.944626,7.16373279 2.944626,6.982052 C2.944626,6.80037122 2.95511855,6.62054264 2.97160186,6.44225126 L7.50496496,6.44225126 L7.50496496,5.07268599 L3.26746839,5.07268599 C3.59564398,4.10161288 4.21548878,3.26709427 5.03474916,2.69333442 C6.25820308,1.83771238 7.8555744,1.83771238 9.07902832,2.69333442 L9.8,1.54899833 C8.14007391,0.393823814 5.97758239,0.393006088 4.31685469,1.54692493 C3.12973738,2.38085999 2.26911733,3.63207666 1.89174263,5.07268599 L0,5.07268599 L0,6.44225126 L1.65499093,6.44225126 C1.64159958,6.62110404 1.63237199,6.80081642 1.63237199,6.982052 C1.63237199,7.16328759 1.64159958,7.34299997 1.65499093,7.52185275 L0,7.52185275 L0,8.89141802 L1.89168361,8.89141802 C2.26836611,10.3309599 3.12790576,11.5814265 4.31377882,12.4151057 Z" id="Path"></path>
				</g>
		</g>
	</svg>;
}

function RollBackSvg() {
	return <svg width="1em" height="1em" viewBox="0 0 20 20" version="1.1">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Group-6">
						<rect id="Rectangle" fill="#FFD700" x="0" y="0" width="20" height="20"></rect>
						<g id="code_SUBSTITUTION" transform="translate(4.000000, 3.000000)" fill="#FFFFFF" fillRule="nonzero">
								<path d="M11,2.42749426 L7.95143662,7.9699473 L6.37492098,5.57978469 C5.06097173,5.73221026 3.16937642,6.13921895 2.8537381,7.308896 C2.38055995,9.34285843 5.63917644,12.8 5.63917644,12.8 C5.63917644,12.8 -0.352365108,9.44393496 0.0163451414,6.74946174 C0.331424809,4.56307374 2.90681003,3.49501374 4.64030686,3.03773704 L3.11630449,0.8 L11,2.42749426 Z" id="Path" transform="translate(5.500000, 6.800000) scale(-1, 1) translate(-5.500000, -6.800000) "></path>
						</g>
				</g>
		</g>
	</svg>;
}

function FacebookIconSvg() {
	return <svg width="1em" height="1em" viewBox="0 0 24 49" version="1.1">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="iconfinder_06-facebook_104498" fill="#FFFFFF">
				<path d="M23.43,15.739 L15.785,15.739 L15.785,10.725 C15.785,8.842 17.033,8.403 17.912,8.403 C18.789,8.403 23.307,8.403 23.307,8.403 L23.307,0.125 L15.877,0.096 C7.629,0.096 5.752,6.27 5.752,10.221 L5.752,15.739 L0.982,15.739 L0.982,24.269 L5.752,24.269 C5.752,35.216 5.752,48.406 5.752,48.406 L15.785,48.406 C15.785,48.406 15.785,35.086 15.785,24.269 L22.555,24.269 L23.43,15.739 Z" id="Path"></path>
			</g>
		</g>
	</svg>;
}

function MoutainsSvg() {
	return <svg width="30px" height="30px" viewBox="0 0 100 100" version="1.1">
    <g id="mountain" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="montagna-color" transform="translate(9.000000, 38.000000)" stroke="#F42C04" strokeWidth="6.0483871">
            <polyline id="Stroke-2" points="0.901435625 34.0291122 16.6220279 16.0403747 29.8398394 26.990041 50.7615231 0.397994299 78.7222782 34.0291122"></polyline>
        </g>
        <g id="montagna-color" transform="translate(9.000000, 33.000000)" stroke="#222222" strokeWidth="6.0483871">
            <polyline id="Stroke-2" points="0.901435625 34.0291122 16.6220279 16.0403747 29.8398394 26.990041 50.7615231 0.397994299 78.7222782 34.0291122"></polyline>
        </g>
    </g>
</svg>
}

function FlatSvg() {
	return <svg width="30px" height="30px" viewBox="0 0 100 100" version="1.1">
    <g id="sprint" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="pianeggiante-color" transform="translate(7.000000, 45.000000)" stroke="#49D49D" strokeWidth="6">
            <path d="M1.24318813,11.1177602 C5.03733097,7.46902496 10.6658667,4.80595289 16.8668667,3.72528599 C19.5662238,3.25422605 22.3909381,3.07609415 25.1376524,3.38386649 C30.8358309,4.02316214 35.6244738,6.63972194 40.5078309,8.82084821 C47.9275809,12.1341017 56.4045096,14.6338862 65.1655809,14.4735675 C71.0053667,14.3660286 78.7478934,13.8649679 88.3931609,12.9703854" id="Stroke-3" transform="translate(44.818175, 8.855865) rotate(4.000000) translate(-44.818175, -8.855865) "></path>
        </g>
        <g id="pianeggiante-color" transform="translate(7.000000, 41.000000)" stroke="#222222" strokeWidth="6">
            <path d="M1.24318813,11.1177602 C5.03733097,7.46902496 10.6658667,4.80595289 16.8668667,3.72528599 C19.5662238,3.25422605 22.3909381,3.07609415 25.1376524,3.38386649 C30.8358309,4.02316214 35.6244738,6.63972194 40.5078309,8.82084821 C47.9275809,12.1341017 56.4045096,14.6338862 65.1655809,14.4735675 C71.0053667,14.3660286 78.7478934,13.8649679 88.3931609,12.9703854" id="Stroke-3" transform="translate(44.818175, 8.855865) rotate(4.000000) translate(-44.818175, -8.855865) "></path>
        </g>
    </g>
</svg>
}

function HillsSvg() {
	return <svg width="30px" height="30px" viewBox="0 0 100 100" version="1.1">
    <g id="hills" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="collinare-color" transform="translate(5.000000, 41.000000)" stroke="#B3D7FF" strokeWidth="6.39886242">
            <g id="Group-5" transform="translate(44.956097, 14.643974) scale(-1, 1) translate(-44.956097, -14.643974) translate(0.456097, 0.143974)">
                <path d="M0.137139554,28.7675907 C4.3973658,19.6202005 10.0825846,11.1440025 17.2355753,4.00723842 C19.042631,2.20460807 21.1048874,0.401977718 23.6347655,0.0582683298 C26.1218643,-0.281015615 28.6237146,0.897627308 30.4573231,2.6117488 C32.2909314,4.32587032 33.5713598,6.53416624 34.8267103,8.70853377 C37.4864014,13.3198451 40.1460924,17.9311565 42.8057837,22.5409927 C44.100963,24.7876425 45.9448976,27.3425981 48.5308312,27.1567295 C50.0959629,27.0446183 51.374916,25.9161304 52.524056,24.8466484 C56.2325769,21.394803 60.281857,15.6682801 65.4611001,14.867275 C71.4192213,13.9467829 76.4317728,18.0403174 80.4781027,21.8934029 C82.7218021,24.0294166 85.4345985,26.5784717 88.4188222,28.2099849" id="Stroke-3"></path>
            </g>
        </g>
        <g id="collinare-color" transform="translate(5.000000, 35.000000)" stroke="#222222" strokeWidth="6.39886242">
            <g id="Group-5" transform="translate(44.956097, 14.643974) scale(-1, 1) translate(-44.956097, -14.643974) translate(0.456097, 0.143974)">
                <path d="M0.137139554,28.7675907 C4.3973658,19.6202005 10.0825846,11.1440025 17.2355753,4.00723842 C19.042631,2.20460807 21.1048874,0.401977718 23.6347655,0.0582683298 C26.1218643,-0.281015615 28.6237146,0.897627308 30.4573231,2.6117488 C32.2909314,4.32587032 33.5713598,6.53416624 34.8267103,8.70853377 C37.4864014,13.3198451 40.1460924,17.9311565 42.8057837,22.5409927 C44.100963,24.7876425 45.9448976,27.3425981 48.5308312,27.1567295 C50.0959629,27.0446183 51.374916,25.9161304 52.524056,24.8466484 C56.2325769,21.394803 60.281857,15.6682801 65.4611001,14.867275 C71.4192213,13.9467829 76.4317728,18.0403174 80.4781027,21.8934029 C82.7218021,24.0294166 85.4345985,26.5784717 88.4188222,28.2099849" id="Stroke-3"></path>
            </g>
        </g>
    </g>
</svg>
}

function TimeSvg() {
	return <svg width="30px" height="30px" viewBox="0 0 100 100" version="1.1">
    <g id="time" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="crono-color" transform="translate(12.000000, 6.000000)">
            <line x1="37.7729138" y1="2.19375" x2="37.7729138" y2="23.166" id="Stroke-9" stroke="#222222" strokeWidth="7.2"></line>
            <path d="M64.87629,25.0205625 C64.87629,25.0205625 66.9011212,22.857525 75.3404773,14.14395" id="Stroke-5" stroke="#222222" strokeWidth="7.2"></path>
            <path d="M10.8401775,26.3705625 C10.8401775,26.3705625 8.81534627,24.207525 0.37599,15.49395" id="Stroke-7" stroke="#222222" strokeWidth="7.2"></path>
            <path d="M37.7729138,87.75 C57.6768073,87.75 73.8118388,71.6149688 73.8118388,51.711075 C73.8118388,31.8071813 57.6768073,15.67215 37.7729138,15.67215 C17.86902,15.67215 1.73398875,31.8071813 1.73398875,51.711075 C1.73398875,71.6149688 17.86902,87.75 37.7729138,87.75" id="Fill-1" stroke="#000000" strokeWidth="5" fill="#FFAE03" fillRule="nonzero"></path>
            <line x1="22.3278169" y1="2.19375" x2="53.2180108" y2="2.19375" id="Stroke-3" stroke="#222222" strokeWidth="7.2"></line>
            <path d="M37.7729138,59.4337331 C42.0375638,59.4337331 45.4971077,55.9763831 45.4971077,51.7117331 C45.4971077,47.4470831 42.0375638,43.9875392 37.7729138,43.9875392 C33.5082638,43.9875392 30.0509138,47.4470831 30.0509138,51.7117331 C30.0509138,55.9763831 33.5082638,59.4337331 37.7729138,59.4337331 Z" id="Stroke-11" stroke="#222222" strokeWidth="7.2" fill="#FFFFFF"></path>
            <line x1="43.2765938" y1="56.5020058" x2="58.2642938" y2="71.4875119" id="Stroke-13" stroke="#222222" strokeWidth="7.2"></line>
        </g>
    </g>
</svg>
	
}

export {
	CaptainButtonSvg,
	RollBackSvg,
	SwapButtonSvg,
	DeleteButtonSvg,
	AddButtonSvg,
	BikeSvg,
	EuroSvg,
	FacebookIconSvg,
	MoutainsSvg,
	FlatSvg,
	HillsSvg,
	TimeSvg
};