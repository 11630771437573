import React, {Component} from "react";
import {MatchDaySelectorStyle, ButtonStyle} from "./MatchDaySelectorStyle";
import { Icon } from 'antd';
import {FlatSvg, HillsSvg, MoutainsSvg, TimeSvg} from './../../styles/custom-icons';

export interface Props {
	day: number;
	match: any;
	min?: number;
	max?: number;
	onNext: any;
	onPrev: any;
}

const FlatIcon = (props: any) => <Icon component={FlatSvg} {...props} />;
const HillsIcon = (props: any) => <Icon component={HillsSvg} {...props} />;
const MountainsIcon = (props: any) => <Icon component={MoutainsSvg} {...props} />;
const TimeIcon = (props: any) => <Icon component={TimeSvg} {...props} />;

function getRaceIcon(type?: string) {
	if(type == 'flat') { return <FlatIcon/> }
	if(type == 'hills') { return <HillsIcon/> }
	if(type == 'mountains') { return <MountainsIcon/> }
	if(type == 'time') { return <TimeIcon/> }
}

export default class MatchDaySelector extends Component<Props> {
	render() {
		// const icon = getRaceIcon(this.props.match);
		const disablePrevious = this.props.min && this.props.day === this.props.min;
		const disableNext = this.props.max && this.props.day === this.props.max;
		const races = this.props.match.map((m: any) => <React.Fragment key={m.id}>{m.feedUrl} {getRaceIcon(m.type)} </React.Fragment> );
		return (
			<MatchDaySelectorStyle>
				<ButtonStyle
					type="previous"
					className={disablePrevious ? 'disabled' : ''}
					onClick={disablePrevious ? () => ({}) : this.props.onPrev}>
				</ButtonStyle>
				<div style={{justifyContent: 'initial'}}>Speeldag {this.props.day} - {races} </div>
				<ButtonStyle
					type="next"
					className={disableNext ? 'disabled' : ''}
					onClick={disableNext ? () => ({}) : this.props.onNext}>
				</ButtonStyle>
			</MatchDaySelectorStyle>
		);
	}
};
