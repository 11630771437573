const theme = {
	primaryColor: "#FFD700",
	primaryContrast: '#FFD700',
	positionGk: '#4ab969',
	positionDf: '#2cb3c7',
	positionMf: '#ffc333',
	positionFw: '#fb6b5b',
	secondaryColor: '#DA291C'
};

export {
	theme
};