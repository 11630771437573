import {PLAYERS_LOADING_STARTED, PLAYERS_FETCH_FAILED, PLAYERS_FETCHED_WITH_SUCCESS} from './actions-types';
import {Player} from './../types'
import http from './../lib/http';
import {Dispatch} from "redux";
import {players} from '../cache';

export interface LoadPlayers {
	type: PLAYERS_LOADING_STARTED
}

export interface PlayersFetchedWithSuccess {
	type: PLAYERS_FETCHED_WITH_SUCCESS;
	data: Player[];
}

export interface PlayersFetchFailed {
	type: PLAYERS_FETCH_FAILED;
}

export type PlayersAction = LoadPlayers | PlayersFetchedWithSuccess | PlayersFetchFailed ;

export function loadPlayers(): LoadPlayers {
	return {
		type: PLAYERS_LOADING_STARTED
	}
}

export function playersFetchedWithSucces(data: any[]): PlayersFetchedWithSuccess {
	return {
		type: PLAYERS_FETCHED_WITH_SUCCESS,
		data
	}
}

export function playersFetchFailed(): PlayersFetchFailed {
	return {
		type: PLAYERS_FETCH_FAILED
	}
}

export function fetchPlayers(competitionFeed: string, seasonId: number) {
	return function (dispatch: Dispatch<PlayersAction>) {
		dispatch(loadPlayers());
		//dispatch(playersFetchedWithSucces(players));

		return http.get(`players`, {competitionFeed, seasonId})
			.then((result: any) => {
				dispatch(playersFetchedWithSucces(result.players));
			})
			.catch((error: any) => {
				dispatch(playersFetchFailed());
			});
	};
}

export function getPlayerDetails(competitionFeed: string, seasonId: number, playerId: number) {
	return http.get(`player/${playerId}`, {competitionFeed, seasonId});
}
