import React, { Component, createRef } from "react";
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Element, scroller } from 'react-scroll'
import { Icon, notification, Divider, Tag, Tooltip } from 'antd';


import * as userActions from '../../actions/user';
import * as teamsActions from '../../actions/teams';
import { StoreState, ApplicationState, UserState, Team, MatchesState } from '../../types';
import { BasicTableStyle } from '../PlayerList/PlayerListStyle';
import Link from '../UI/Link/Link';
import { DashboardStyle, ActionsList, EditableTeamNameStyle, TeamNameWithEditButton, PaidLabel } from './DashboardStyles';

import { Title, TitleType, PlayerList, PlayerType, Block, Button, Row, Col, Checkbox, Input } from '..';

export interface Props {
	application: ApplicationState;
	user: UserState;
	updateTeamName: any;
	copyTeam: any;
	dropTeam: any;
	match: any;
	matches: MatchesState
}

interface EditingTeam {
	id: number;
	name: string;
};

export interface State {
	editingTeams: EditingTeam[]
}

class Dashboard extends Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			editingTeams: []
		};
	};

	onTeamNameEdit = (team: Team) => {
		this.setState({
			editingTeams: [...this.state.editingTeams, { id: team.id, name: team.name }]
		})
	};

	onTeamNameChange = (teamId: number, name: string) => {
		this.setState({
			editingTeams: this.state.editingTeams.map((item: EditingTeam) => {
				return item.id === teamId ? { ...item, name } : item;
			})
		});
	}

	onTeamNameCancelEdit = (teamId: number) => {
		this.setState({
			editingTeams: this.state.editingTeams.filter((item: EditingTeam) => {
				return item.id !== teamId;
			})
		});
	}

	onTeamNameSave = (teamId: number) => {
		const editingTeam = this.state.editingTeams.find((item: EditingTeam) => item.id === teamId);
		if (editingTeam) {
			this.props.updateTeamName(teamId, editingTeam.name);
			this.onTeamNameCancelEdit(teamId);
		}
	};

	onCopyTeamHandler = (teamId: number) => {
		this.props.copyTeam(teamId);
	};

	render() {
		const { application, user, match } = this.props;
		const deadlineWeek = (this.props.matches && this.props.matches.info && this.props.matches.info.deadlineWeek) || 0;

		const teams = user.teams || [];

		const columns = [{
			key: 'name',
			title: 'Team',
			dataIndex: 'name',
			width: '40%',
			render: (text: string, record: any) => {
				const editRecord = this.state.editingTeams.find((item: EditingTeam) => item.id === record.id);

				return editRecord ?
					<EditableTeamNameStyle>
						<Input
							onChange={(e: any) => this.onTeamNameChange(record.id, e.target.value)}
							value={editRecord.name}
						/>
						<Icon type="close-circle" onClick={(e: any) => this.onTeamNameCancelEdit(record.id)} theme="filled" />
						<Icon type="check-circle" onClick={(e: any) => this.onTeamNameSave(record.id)} theme="filled" />
					</EditableTeamNameStyle> :
					<TeamNameWithEditButton>
						<span className="team-name">
							<Link to={`${match.url}/team/${record.id}`}>{record.name}</Link>
						</span>
						<Icon
							onClick={(e: any) => this.onTeamNameEdit(record)}
							type="edit"
							theme="filled" />
					</TeamNameWithEditButton>;
			}
		},
		{
			key: 'paid',
			title: 'Betaald',
			className: 'paid-column',
			dataIndex: 'paid',
			width: '10%',
			render: (text: string, record: any) => {
				return <PaidLabel>
					{
						record.paid ?
							<Tooltip title="Betaald">
								<span className="badge"> <Icon type="check-circle" /></span>
							</Tooltip> :
							<Tooltip title="Niet betaald">
								<span className="badge"> <Icon type="exclamation-circle" /></span>
							</Tooltip>
					}
				</PaidLabel>
			}
		},
		{
			key: 'points',
			title: <Tooltip title="Punten"> <span>P</span></Tooltip>,
			dataIndex: 'points',
			width: '10%',
			render: (text: string, record: any) => {
				return <b>{record.points ? record.points : '-'}</b>
			}
		},
		{
			key: 'rank',
			title: <Tooltip title="Rangschikking"> <span>R</span></Tooltip>,
			dataIndex: 'rank',
			width: '10%',
			render: (text: string, record: any) => {
				return <b>{record.rank ? record.rank : '-'}</b>
			}
		}
		];

		if (deadlineWeek) {
			columns.push({
				key: 'id',
				title: 'Acties',
				dataIndex: 'id',
				width: '30%',
				render: (text: string, record: any) => {
					return <ActionsList>
						<li onClick={(e: any) => this.onCopyTeamHandler(record.id)}>
							<Icon style={{ color: '#FFD700', fontSize: '24px' }} type="plus-square" theme="filled" />
							<span className={'label'}> Kopieer </span>
						</li>
						{
							!record.paid ?
								<li onClick={(e: any) => this.props.dropTeam(record.id)}>
									<Icon style={{ color: '#FF2E4A', fontSize: '24px' }} type="delete" theme="filled" />
									<span className={'label'}> Verwijder </span>
								</li> : null
						}
					</ActionsList>;
				}
			});
		}

		return (
			<DashboardStyle>
				<Row>
					<Col md={24} sm={24} xs={24}>
						<Block>
							<Title type={TitleType.h2}>Overzicht</Title>
							<BasicTableStyle
								columns={columns}
								dataSource={teams}
								showHeader={true}
								locale={{ emptyText: 'Je hebt geen betaalde teams.' }}
								loading={false}
								pagination={false}
								rowKey={(record: any, index: number) => `record-${index + 1}`}
								rowClassName={(record: object, index: number) => index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'}
							/>
						</Block>
					</Col>
				</Row>
			</DashboardStyle>
		);
	}
}

const mapDispatchToProps = {
	updateTeamName: teamsActions.updateTeamName,
	copyTeam: teamsActions.copy,
	dropTeam: teamsActions.drop,
};


export function mapStateToProps({ matches }: StoreState.All) {
	return {
		matches
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);