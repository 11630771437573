import React, { Component } from "react";
import { Form, notification } from 'antd';
import moment from 'moment';
import lockr from 'lockr';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { pick } from 'lodash';
import { Link } from 'react-router-dom';

import * as userActions from '../../actions/user'
import { Layout, Row, Col, Button, Input, InputPassword, Block, FormItem, Title, TitleType } from '../../components';

import { UserProfileStyles } from "./UserProfileStyles";
import { H2, PageStyle, H1, H3 } from "../../styles/global";
import { Redirect } from "react-router";
import { StoreState, UserState, ApplicationState } from '../../types';

interface Props {
	location: any,
	onSetProfileData: any
	fetchUserTeams: any;
	user: UserState;
	match: any;
	application: ApplicationState;
}

interface ValidationObject {
	status: string,
	message: string
}

interface LoginFormValues {
	password: string,
	passwordValidation: ValidationObject,
	passwordConfirm: string,
	passwordConfirmValidation: ValidationObject,
}

interface ProfileEditProps {
	form: any;
	user: any;
	onSetProfileData: any;
}

interface ProfileEditValues {
	firstName: string,
	lastName: string,
	city: string,
	street: string,
	streetNr: string,
	postCode: string,
}

class ProfileEdit extends Component<ProfileEditProps> {

	handleSubmit = (e: any) => {
		e.preventDefault();
		this.props.form.validateFields((err: any, values: ProfileEditValues) => {
			if (!err) {
				userActions.updateProfile(
					values.firstName,
					values.lastName,
					values.city,
					values.street,
					values.streetNr,
					values.postCode
				)
					.then((result: any) => {
						const userData = { ...this.props.user.data, ...values };
						lockr.set('user', userData);
						this.props.onSetProfileData(userData);
						notification.success({ message: 'Profiel aangepast.' });
					})
					.catch(() => {
						notification.error({ message: 'Er ging iets verkeerd. Contacteer ons via de chat.' });
					})
			}
		});
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const { user } = this.props;
		const dobFormat = 'DD/MM/YYYY';

		return (
			<Form onSubmit={this.handleSubmit} className="register-form">
				<FormItem>
					{getFieldDecorator('firstName', {
						initialValue: user.data.firstName || undefined,
						rules: [{ required: true, message: 'Vul je voornaam in!' }],
					})(
						<Input placeholder="Voornaam" />
					)}
				</FormItem>

				<FormItem>
					{getFieldDecorator('lastName', {
						initialValue: user.data.lastName || undefined,
						rules: [{ required: true, message: 'Vul je achternaam in!' }],
					})(
						<Input placeholder="Achternaam" />
					)}
				</FormItem>

				<FormItem>
					{getFieldDecorator('street', {
						initialValue: user.data.street || undefined,
						rules: [],
					})(
						<Input placeholder="Straat" />
					)}
				</FormItem>

				<FormItem>
					{getFieldDecorator('streetNr', {
						initialValue: user.data.streetNr || undefined,
						rules: [],
					})(
						<Input placeholder="Huisnummer" />
					)}
				</FormItem>

				<FormItem>
					{getFieldDecorator('postCode', {
						initialValue: user.data.postCode || undefined,
						rules: [],
					})(
						<Input placeholder="Postcode" />
					)}
				</FormItem>

				<FormItem>
					{getFieldDecorator('city', {
						initialValue: user.data.city || undefined,
						rules: [],
					})(
						<Input placeholder="Gemeente" />
					)}
				</FormItem>


				<div style={{ textAlign: 'right' }}>
					<Button type="primary" htmlType="submit" style={{ marginBottom: '20px' }}>Bewaar profiel</Button>
				</div>
			</Form>
		);
	}
}

const defaultValidationObject = { status: '', message: '' };

class UserProfile extends Component<Props, LoginFormValues> {
	state = {
		passwordConfirm: '',
		passwordConfirmValidation: defaultValidationObject,
		password: '',
		passwordValidation: defaultValidationObject,
	}

	handlePasswordUpdate = (e: any) => {
		e.preventDefault();

		if (this.state.passwordConfirmValidation.status !== 'error' && this.state.passwordValidation.status !== 'error') {
			userActions.updatePassword(this.state.password)
				.then(() => {
					notification.success({ message: 'Wachtwoord aangepast.' });
					this.setState({
						passwordConfirm: '',
						passwordConfirmValidation: defaultValidationObject,
						password: '',
						passwordValidation: defaultValidationObject
					});
				})
				.catch(() => {
					notification.error({ message: 'Er ging iets verkeerd. Contacteer ons via de chat.' });
				});
		}
	}

	onPasswordChange = (e: any) => {
		const newState = { password: e.target.value, passwordValidation: this.state.passwordValidation };
		if (!e.target.value) {
			newState.passwordValidation = { status: 'error', message: 'Vul je wachtwoord in!' }
		} else if (e.target.value && e.target.value.length < 6) {
			newState.passwordValidation = { status: 'error', message: 'Het wachtwoord moet minstens 6 tekens lang zijn!' }
		} else {
			newState.passwordValidation = defaultValidationObject;
		}
		this.setState(newState);
	};

	onPasswordConfirmChange = (e: any) => {
		const newState = { passwordConfirm: e.target.value, passwordConfirmValidation: this.state.passwordConfirmValidation };
		if (!e.target.value) {
			newState.passwordConfirmValidation = { status: 'error', message: 'Vul je wachtwoord in!' }
		} else if (e.target.value && e.target.value.length < 6) {
			newState.passwordConfirmValidation = { status: 'error', message: 'Het wachtwoord moet minstens 6 tekens lang zijn!' }
		} else if (e.target.value !== this.state.password) {
			newState.passwordConfirmValidation = { status: 'error', message: 'Beide wachwoorden moeten hetzelfde zijn!' }
		} else {
			newState.passwordConfirmValidation = defaultValidationObject;
		}
		this.setState(newState);
	};

	render() {
		const { password, passwordValidation, passwordConfirm, passwordConfirmValidation } = this.state;
		const { user } = this.props;
		const WrappedProfileForm = Form.create()((props: any) => <ProfileEdit {...props} user={user} onSetProfileData={this.props.onSetProfileData} />);


		return (
			<Layout><UserProfileStyles>
					<Row>
						<Col md={12} sm={24} xs={24}>
							<Block>
								<Title type={TitleType.h2}>Wijzig profiel</Title>
								<WrappedProfileForm />
							</Block>
						</Col>

						<Col md={12} sm={24} xs={24}>
							<Block>
								<Title type={TitleType.h2}>Verander wachtwoord</Title>
								<Form className="login-form">
									<FormItem
										validateStatus={passwordValidation.status}
										help={passwordValidation.message}>
										<InputPassword
											type="password"
											name="password"
											value={password}
											onChange={this.onPasswordChange}
											placeholder="Wachtwoord" />
									</FormItem>

									<FormItem
										validateStatus={passwordConfirmValidation.status}
										help={passwordConfirmValidation.message}>
										<InputPassword
											type="password"
											name="password"
											value={passwordConfirm}
											onChange={this.onPasswordConfirmChange}
											placeholder="Bevestig wachtwoord" />
									</FormItem>
									<div style={{ textAlign: 'right' }}>
										<Button type="primary" onClick={this.handlePasswordUpdate} htmlType="submit">
											Verander wachtwoord
										</Button>
									</div>
								</Form>
							</Block>
						</Col>
					</Row>
			</UserProfileStyles></Layout>
		);
	}
}

export const mapDispatchToProps = {
	onSetProfileData: (data: any) => (dispatch: any) => dispatch(userActions.setProfileData(data)),
	fetchUserTeams: userActions.fetchTeams
};

export function mapStateToProps({ user, application, matches }: StoreState.All) {
	return {
		user,
		application
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
