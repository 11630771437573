import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Redirect } from 'react-router-dom';

import * as userActions from '../../actions/user';
import * as leaguesActions from '../../actions/leagues';
import {
	ApplicationState,
	StoreState,
	UserState,
	MatchesState,
} from './../../types';
import {
	ContainerStyle,
	SelectGroupStyle,
	TableStyle,
} from './../../components/PlayerList/PlayerListStyle';
import { TeamsList, Select, Input } from './../../components';
import Hashids from 'hashids';
import config from './../../config';
import CopyToClipboard from 'react-copy-to-clipboard';
import lockr from 'lockr';
import { Element, scroller } from 'react-scroll';
import { Icon } from 'antd';

import {
	Title,
	TitleType,
	Block,
	Row,
	Col,
	Button,
	LeaguesList,
	Alert,
} from './../../components';
const hashIdInstance = new Hashids(config.HASH_SALT, 12);



export interface Props {
	user: UserState;
	application: ApplicationState;
	matches: MatchesState;
	location: any;
	match: any;
}

export interface State {
	selectedLeagueInfo: any;
	selectedLeagueId: number | null;
	searchLeagueName: string;
	searchLeagueCode: string;
	searchLeagueResult: any[] | null;
	createLeagueName: string;
	invitedLeagueId: number | null;
	invitedLeagueInfo: any;
	addingNewLeague: boolean;
	selectedWeekId: number;
	initialized: boolean;
}

class Leagues extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			selectedLeagueInfo: {},
			selectedLeagueId:
				(props.match.params.leagueId &&
					parseInt(props.match.params.leagueId)) ||
				null,
			searchLeagueName: '',
			searchLeagueCode: '',
			createLeagueName: '',
			searchLeagueResult: null,
			invitedLeagueId: null,
			invitedLeagueInfo: {},
			addingNewLeague: false,
			selectedWeekId: -1,
			initialized: false
		};
	}

	componentDidMount() {
		leaguesActions.fetchTeamLeagues(this.props.match.params.id)
			.then(response => {
				if (response && response.leagues && response.leagues.length) {
					const league = response.leagues[0];

					this.initLeagueById(league.id)
				} else {
					this.setState({ initialized: true });
				}

				if (lockr.get('inviteCode')) {
					const invitedLeagueId = hashIdInstance.decode(lockr.get('inviteCode'))[0];
					
					leaguesActions
						.fetchLeagueInfo(invitedLeagueId)
						.then(invitedLeagueInfo => {
							this.setState({ invitedLeagueId, invitedLeagueInfo });
						});

					scroller.scrollTo('join-leagues', {
						duration: 1000,
						delay: 100,
						smooth: true,
						offset: 100,
					});
				}
			});
	}

	initLeagueById = (leagueId: number) => {
		return leaguesActions.fetchLeagueInfo(leagueId)
			.then(leagueInfo => {
				this.setState({
					selectedLeagueInfo: leagueInfo,
					selectedLeagueId: leagueId,
					initialized: true
				});
			});
	};

	loadLeagueById = (id: number, weekId?: number) => {
		const loadingWeekId = weekId || this.state.selectedWeekId;

		leaguesActions
			.fetchLeagueInfo(
				id,
				loadingWeekId && loadingWeekId > 0 ? loadingWeekId : null
			)
			.then(leagueInfo => {
				this.setState({
					selectedLeagueInfo: leagueInfo,
					selectedLeagueId: id,
					initialized: true
				});
			});
	};

	onLeagueSelect = (leagueId: any) => {
		this.loadLeagueById(leagueId);
	};

	onSearchLeagueNameChange = (e: any) => {
		this.setState({ searchLeagueName: e.target.value });
	};

	onSearchLeagueCodeChange = (e: any) => {
		this.setState({ searchLeagueCode: e.target.value });
	};

	onNewLeagueNameChange = (e: any) => {
		this.setState({ createLeagueName: e.target.value });
	};

	onSearch = (e: any) => {
		leaguesActions
			.searchLeagues(
				this.state.searchLeagueName,
				this.props.application.competition.competitionFeed,
				this.props.application.competition.seasonId
			)
			.then((searchResult: any) => {
				this.setState({ searchLeagueResult: searchResult.leagues });
			});
	};

	onSearchCode = (e: any) => {
		const leagueId = hashIdInstance.decode(this.state.searchLeagueCode)[0];

		leaguesActions
			.searchLeagueId(
				leagueId,
				this.props.application.competition.competitionFeed,
				this.props.application.competition.seasonId
			)
			.then((searchResult: any) => {
				this.setState({ searchLeagueResult: searchResult.leagues });
			});
	};

	onLeagueCreate = (e: any) => {
		const team =
			this.props.user &&
			this.props.user.teams &&
			this.props.user.teams.find((team: any, index: number) => team.id === parseInt(this.props.match.params.id));

		if (team) {
			this.setState({ addingNewLeague: true });

			leaguesActions
				.createLeague(
					this.state.createLeagueName,
					this.props.application.competition.competitionFeed,
					this.props.application.competition.seasonId,
					team.id
				)
				.then((result: any) => {
					this.setState({createLeagueName: ''});
					this.loadLeagueById(result.league.id);
					this.setState({ addingNewLeague: false });
				});
		}
	};

	onJoinLeague = (league: any) => {
		const team =
			this.props.user &&
			this.props.user.teams &&
			this.props.user.teams.find((team: any, index: number) => team.id === parseInt(this.props.match.params.id));

		if (team) {
			leaguesActions.joinLeague(league.id, team.id).then(joinResult => {
				this.setState({
					searchLeagueResult: null,
					searchLeagueName: '',
					searchLeagueCode: ''
				});
				this.loadLeagueById(league.id);
			});
		}
	};

	onInviteLeagueCancel = () => {
		lockr.rm('inviteCode');
		this.setState({ invitedLeagueId: null, invitedLeagueInfo: {} });
	};

	onInviteLeagueJoin = (league: any) => {
		lockr.rm('inviteCode');
		this.setState({ invitedLeagueId: null, invitedLeagueInfo: {} });
		this.onJoinLeague(league);
	};

	onTeamKick = (teamId: number, leagueId: number) => {
		if (this.state.selectedLeagueId) {
			leaguesActions.leaveLeague(leagueId, teamId).then(() => {
				this.loadLeagueById(leagueId);
			});
		}
	};

	onLeagueLeave = () => {
		const team =
			this.props.user &&
			this.props.user.teams &&
			this.props.user.teams.find((team: any, index: number) => team.id === parseInt(this.props.match.params.id));

		if (team) {
			if (team && team.id && this.state.selectedLeagueId && team.weekId === this.props.matches.info.deadlineWeek) {
				leaguesActions
					.leaveLeague(this.state.selectedLeagueId, team.id)
					.then(() => {
						this.setState({
							searchLeagueResult: null,
							searchLeagueName: '',
							searchLeagueCode: '',
							selectedLeagueId: null,
						});
					});
			}
		}
	};

	onLeagueRemove = () => {
		const team =
			this.props.user &&
			this.props.user.teams &&
			this.props.user.teams.find((team: any, index: number) => team.id === parseInt(this.props.match.params.id));

		if (team && team.id && this.state.selectedLeagueId) {
			leaguesActions.removeLeague(this.state.selectedLeagueId).then(() => {
				this.setState({
					searchLeagueResult: null,
					searchLeagueName: '',
					searchLeagueCode: '',
					selectedLeagueId: null,
				});
			});
		}
	};

	onWeekSelect = (weekId: any) => {
		this.setState({ selectedWeekId: weekId });
		const { selectedLeagueId } = this.state;
		if (selectedLeagueId) {
			this.loadLeagueById(selectedLeagueId, weekId);
		}
	};

	render() {
		const { user } = this.props;
		const {
			selectedLeagueId,
			selectedLeagueInfo,
			searchLeagueName,
			searchLeagueCode,
			createLeagueName,
			searchLeagueResult,
			invitedLeagueId,
			invitedLeagueInfo,
			addingNewLeague,
			selectedWeekId,
			initialized
		} = this.state;
		const teams = selectedLeagueInfo.teams || [];
		const isAdmin =
			selectedLeagueInfo &&
			selectedLeagueInfo.league &&
			selectedLeagueInfo.league.admin === user.data.id;
		const team = user && user.teams && user.teams[0];
		const canLeave = team && team.weekId === this.props.matches.info.deadlineWeek;
		const joinIgnoreLeagueIds = user.leagues.map((league: any) => league.id);
		let selectedLeagueInviteUrl = '';

		let weeks = this.props.matches.weeks.map((week: any) => {
			return {
				id: week.weekId,
				name: `Speeldag ${week.weekId}`,
			};
		});

		weeks.unshift({ id: -1, name: 'Alle Speeldagen' });

		if (selectedLeagueId) {
			selectedLeagueInviteUrl = `${hashIdInstance.encode(selectedLeagueId)}`;
		}

		return (
			<React.Fragment>
				{user.fetched && !team && <Redirect to={{ pathname: `/new` }} />}
				<Block>
						{(initialized && !selectedLeagueId && (
							<Block>
								<Alert
									style={{ textAlign: 'center' }}
									message="Jouw team zit nog niet in een superklasse. Je kan een bestaande superklasse zoeken of er zelf eentje aanmaken."
									type="info"
									showIcon
								/>
							</Block>
						)) ||
							null}
						{(selectedLeagueId && (
							<React.Fragment>
								<Block className="no-margin">
									<Title type={TitleType.h2}>{selectedLeagueInfo.league.name}</Title>
									<Select
										keyProperty={'id'}
										style={{ width: '100%' }}
										value={selectedWeekId}
										notFoundContent={'No weeks.'}
										textProperty={'name'}
										onSelect={this.onWeekSelect}
										values={weeks}
									/>
								</Block>
								<Block className="no-margin">
									<TeamsList
										data={teams}
										size={10}
										showHeader={true}
										adminTeamId={(team && team.id) || null}
										isAdmin={isAdmin}
										onTeamKick={(team: any) =>
											this.onTeamKick(team.id, selectedLeagueId)
										}
										matches={this.props.matches}
									/>
								</Block>

								<Block className="no-margin">
									<Title type={TitleType.h2}>INSTELLINGEN</Title>

									<Alert
										style={{ textAlign: 'center' }}
										message="Deel deze code met vrienden, familie en collega’s. Vraag ze om een ploeg aan te maken en de code op de superklasse pagina in te geven."
										type="info"
										showIcon
									/>
									<Row style={{ marginTop: '5px' }}>
										<Col md={17} sm={24} xs={24}>
											<Block style={{ margin: '0' }}>
												<Input
													disabled={true}
													value={selectedLeagueInviteUrl}
												/>
											</Block>
										</Col>

										<Col md={7} sm={24} xs={24}>
											<Block style={{ margin: '0' }}>
												<div style={{ textAlign: 'right' }}>
													<CopyToClipboard text={selectedLeagueInviteUrl}>
														<Button style={{ marginTop: '3px' }} size="small">
															<Icon type="copy" />
															Kopieer code
															</Button>
													</CopyToClipboard>
												</div>
											</Block>
										</Col>
									</Row>
								</Block>

								<Block className="no-margin">
									<Row style={{ marginTop: '5px', textAlign: 'center' }}>
										{(canLeave && (
											<Button
												style={{ margin: '3px', color: '#000' }}
												onClick={this.onLeagueLeave}
												type="danger"
												size="small"
											>
												<Icon type="logout" />
												Verlaat superklasse
											</Button>
										)) || null}

										{(canLeave && isAdmin && (
											<Button
												style={{ margin: '3px', color: '#000' }}
												onClick={this.onLeagueRemove}
												type="danger"
												size="small"
											>
												<Icon type="delete" />
												Verwijder superklasse
												</Button>
										)) || null}
									</Row>
								</Block>
							</React.Fragment>
						)) ||
							null}
					{
						!selectedLeagueId && initialized ?
							<Col md={12} sm={24} xs={24}>
								<Element name="join-leagues">
									<Block className="no-margin">
										<Title type={TitleType.h2}>Toetreden tot een superklasse</Title>

										{invitedLeagueInfo &&
											invitedLeagueInfo.league &&
											invitedLeagueInfo.league.id &&
											joinIgnoreLeagueIds.indexOf(invitedLeagueInfo.league.id) ===
											-1 && (
												<div style={{ paddingBottom: '10px' }}>
													<Alert
														style={{ textAlign: 'center' }}
														message={`Wil je deelnemen aan de superklasse ${
															invitedLeagueInfo.league.name
															}?`}
														type="info"
														showIcon
													/>

													<div style={{ textAlign: 'right' }}>
														<Button
															onClick={this.onInviteLeagueCancel}
															style={{ margin: '5px' }}
															size="small"
														>
															Neen
												</Button>

														<Button
															onClick={(e: any) =>
																this.onInviteLeagueJoin(invitedLeagueInfo.league)
															}
															style={{ margin: '5px' }}
															size="small"
														>
															JA
												</Button>
													</div>
												</div>
											)}

										<Input
											onChange={this.onSearchLeagueCodeChange}
											placeholder={'Geef je code in'}
											value={searchLeagueCode}
										/>
										<div style={{ textAlign: 'right' }}>
											<Button
												style={{ marginTop: '5px' }}
												disabled={!searchLeagueCode.length}
												onClick={this.onSearchCode}
												size="small"
											>
												Zoek
											</Button>
										</div>
										{(searchLeagueResult && (
											<div style={{ marginTop: '5px' }}>
												<LeaguesList
													size={5}
													showHeader={false}
													isRowExpandable={true}
													noLeaguesMessage={'Geen superklasse gevonden.'}
													onJoin={this.onJoinLeague}
													joinIgnoreLeagueIds={joinIgnoreLeagueIds}
													isLoading={false}
													data={searchLeagueResult.map((item, index) =>
														Object.assign({}, item, { recordNumber: index + 1 })
													)}
												/>
											</div>
										)) ||
											null}
									</Block>
								</Element>
							</Col> : null
					}

					{
						!selectedLeagueId && initialized ?
							<Col md={12} sm={24} xs={24}>
								<Block className="no-margin">
									<Title type={TitleType.h2}>Maak een superklasse</Title>
									<Input
										onChange={this.onNewLeagueNameChange}
										placeholder={'Naam'}
										value={createLeagueName}
									/>

									<div style={{ textAlign: 'right' }}>
										<Button
											style={{ marginTop: '5px' }}
											disabled={createLeagueName.length < 3 || addingNewLeague}
											onClick={this.onLeagueCreate}
											size="small"
										>
											Maak een superklasse
									</Button>
									</div>
								</Block>
							</Col>
							: null
					}
				</Block>
			</React.Fragment>
		);
	}
}

export const mapDispatchToProps = {
};

export function mapStateToProps({
	user,
	application,
	matches,
}: StoreState.All) {
	return {
		user,
		application,
		matches,
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Leagues);
