import React, {Component} from "react";
import {Form, notification} from 'antd';
import lockr from 'lockr';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {pick} from 'lodash';
import {Link} from 'react-router-dom';

import * as userActions from '../../actions/user'
import { Layout, Row, Col, Button, Input, FormItem} from '../../components';

import config from '../../config';
import {ForgotStyles} from "./ForgotStyles";
import {H2, PageStyle, H1, H3} from "../../styles/global";
import {Redirect} from "react-router";
import {StoreState, UserState, ApplicationState} from '../../types';

export interface Props {
	location: any,
	onSetProfileData: any
	fetchUserTeams: any;
	user: UserState;
	application: ApplicationState;
}

export interface ValidationObject {
	status: string,
	message: string 
}

export interface ForgotFormValues {
	email: string,
	emailValidation: ValidationObject,
	redirectToHome: boolean;
}

const defaultValidationObject =  {status: '', message: ''};

const validateEmail = (email: string) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
};

class Forgot extends Component<Props, ForgotFormValues> {
	state = {
		email: '',
		emailValidation: defaultValidationObject,
		redirectToHome: false
	}

	handleSubmit = (e: any) => {
		e.preventDefault();

		if(this.state.emailValidation.status !== 'error') {
			userActions.forgot(this.state.email)
				.then(result => {
					notification.success({message: 'Als er een account gekoppeld is aan dat e-mailadres, krijg je zo meteen een bericht.'});
					this.setState({redirectToHome: true});
				})
				.catch(() => {
					notification.error({message: 'Er ging iets verkeerd. Contacteer ons via de chat.'});
					this.setState({redirectToHome: true});
				});
		}
	}

	onEmailChange = (e: any) => {
		const newState = { email: e.target.value, emailValidation: this.state.emailValidation };
		if(!e.target.value) {
			newState.emailValidation = {status: 'error', message: 'Vul je e-mailadres in!'};
		} else if(!validateEmail(e.target.value)) {
			newState.emailValidation = {status: 'error', message: 'Ongeldig e-mailadres!'};
		} else {
			newState.emailValidation = defaultValidationObject;
		}
		this.setState(newState);
	};

	render() {
		const {email, emailValidation} = this.state;

		return (
			<Layout>
			<ForgotStyles>
				{this.state.redirectToHome && <Redirect
					to={{pathname: `/home`}}
				/>}
				<PageStyle>
					<Row gutter={32} style={{backgroundColor: 'rgb(29, 29, 29)', padding: '40px'}}>
						<Col className="gutter-row" md={{span: 10, offset:7}} sm={24} xs={24}>
							<div className="gutter-box" style={{background: '#FFF', padding: '20px', textAlign: 'center'}}>
								<Form className="forgot-form">
									<H3 style={{textAlign: 'left'}}>Wachtwoord vergeten</H3>
									<FormItem
										validateStatus={emailValidation.status}
										help={emailValidation.message}>
											<Input 
												type="email" 
												value={email}
												name="email"
												onChange={this.onEmailChange}
												placeholder="E-mailadres"/>
									</FormItem>

									<Button type="primary" onClick={this.handleSubmit} htmlType="submit" style={{margin: '20px auto'}}>Verstuur nieuw wachtwoord</Button>
									<p><Link to={`/home`} style={{textDecoration: 'underline'}}> Log in</Link></p>
								</Form>
							</div>
						</Col>
					</Row>
				</PageStyle>
			</ForgotStyles></Layout>
		);
	}
}

export const mapDispatchToProps = {
	onSetProfileData: (data: any) => (dispatch: any) => dispatch(userActions.setProfileData(data)),
	fetchUserTeams: userActions.fetchTeams
};

export function mapStateToProps({user, application, matches}: StoreState.All) {
	return {
		user,
		application
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
