import React, { Component } from 'react';
import { TeamStyle, Sponsor } from './TeamStyle';
import Player, { PlayerType } from '../Player/Player';
import {Club} from './../../types';
import { Row, Col } from 'antd';

export interface TeamState {
}

export interface TeamProps {
	bg: string;
	bgSponsor?: string;
	widthRatio: number;
	heightRatio: number;
	playerType: PlayerType;
	selection: any;
	playerBadgeColor: string;
	playerBadgeBgColor: string;
	playerPointsColor: string;
	playerPointsBgColor: string;
	onRemove?: any;
	onSwap?: any;
	isSwapAble?: any;
	onCaptainSelect?: any;
	modalEnabled? : boolean;
	onPlaceholderClick?: any;
	swapPlayerId?: number | null;
	swappedFrom?: string | null;
	assetsCdn: string;
	actionLessPlayerIds?: any[];
	showPlayerValue?: boolean;
	showPlayerStatsPoints?: boolean;
	clubs: Club[];
	showPlayerValueInsteadOfPoints?: boolean;
	captainId?: number;
	centerAligned?: boolean | undefined;
}

class Team extends Component<TeamProps, TeamState> {
	render() {
		const {
			bg,
			bgSponsor,
			widthRatio,
			heightRatio,
			selection,
			playerType,
			playerBadgeColor,
			playerBadgeBgColor,
			playerPointsColor,
			playerPointsBgColor,
			onRemove,
			onSwap,
			isSwapAble,
			onPlaceholderClick,
			swapPlayerId,
			swappedFrom,
			assetsCdn,
			actionLessPlayerIds,
			showPlayerValue,
			showPlayerStatsPoints,
			showPlayerValueInsteadOfPoints,
			clubs,
			onCaptainSelect,
			modalEnabled,
			captainId,
			centerAligned
		} = this.props;

		return (
			<Row><Col>
			<TeamStyle bg={bg} widthRatio={widthRatio} heightRatio={heightRatio} centerAligned={centerAligned}>
				{/* {bgSponsor && <Sponsor bg={bgSponsor} widthRatio={widthRatio} heightRatio={heightRatio} />} */}
				{selection.filter((position:any, index:any) => position[0] === null || position[0] && position[0].positionId === 30).map((position: any, positionIndex: number) => {
				const sportSpecificProps: {
					shirtCycling?: string,
					shirtSoccer?: string,
					soccerJersey?: string,
					clubBadge?: string,
					portraitFace?: string,
					shirtFallback?: string,
					portraitFaceFallBack?: string,
					club?: Club
				} = {};

					return (
						<div className={`position`} key={`positionIndex-${positionIndex}`}>
							{
								position.map((player: any, playerIndex: number) => {

									if ((playerType === PlayerType.Cycling || playerType === PlayerType.Cyclocross) && player) {
										const club = clubs.find((item: Club, index: number) => item.id === player.clubId);

										sportSpecificProps.shirtCycling = `${assetsCdn}/jerseys/cycling/${player.clubId}.png`;
										sportSpecificProps.club = club;
									}

									if (PlayerType.FormulaOne === playerType && player) {
										// sportSpecificProps.soccerJersey = `${assetsCdn}/jerseys/club_${player.clubId}.png`;
										// sportSpecificProps.clubBadge = `${assetsCdn}/badges/club_${player.clubId}.png`;
										sportSpecificProps.portraitFace = `${assetsCdn}/jerseys/formulaone/${player.id}.png`;
										// sportSpecificProps.portraitFaceFallBack = `${assetsCdn}/players/dummy.png`;
									}

									return <Player
										key={`playerIndex-${playerIndex}`}
										pointsColor={playerPointsColor}
										pointsBgColor={playerPointsBgColor}
										badgeColor={playerBadgeColor}
										badgeBgColor={playerBadgeBgColor}
										positionIndex={positionIndex + 1}
										modalEnabled={modalEnabled}
										captainId={captainId}
										player={player}
										showPlayerValue={showPlayerValue}
										showPlayerStatsPoints={showPlayerStatsPoints}
										type={playerType}
										onRemove={onRemove}
										showPlayerValueInsteadOfPoints={showPlayerValueInsteadOfPoints}
										onSwap={onSwap}
										isSwapAble={isSwapAble}
										onCaptainSelect={onCaptainSelect}
										onPlaceholderClick={onPlaceholderClick}
										swapPlayerId={swapPlayerId}
										actionLessPlayerIds={actionLessPlayerIds}
										swappedFrom={swappedFrom}
										{...sportSpecificProps}
									/>
								})
							}
						</div>
					);
				})}
				{selection.filter((position:any, index:any) => position[0] && position[0].positionId !== 30).map((position: any, positionIndex: number) => {
				const sportSpecificProps: {
					shirtCycling?: string,
					shirtSoccer?: string,
					soccerJersey?: string,
					clubBadge?: string,
					portraitFace?: string,
					shirtFallback?: string,
					portraitFaceFallBack?: string,
					club?: Club
				} = {};

					return (
						<div className={`position`} key={`positionIndex-${positionIndex}`}>
							{
								position.map((player: any, playerIndex: number) => {

									if ((playerType === PlayerType.Cycling || playerType === PlayerType.Cyclocross) && player) {
										const club = clubs.find((item: Club, index: number) => item.id === player.clubId);

										sportSpecificProps.shirtCycling = `${assetsCdn}/jerseys/cycling/${player.clubId}.png`;
										sportSpecificProps.club = club;
									}

									if (PlayerType.FormulaOne === playerType && player) {
										// sportSpecificProps.soccerJersey = `${assetsCdn}/jerseys/club_${player.clubId}.png`;
										// sportSpecificProps.clubBadge = `${assetsCdn}/badges/club_${player.clubId}.png`;
										sportSpecificProps.portraitFace = `${assetsCdn}/jerseys/formulaone/${player.id}.png`;
										// sportSpecificProps.portraitFaceFallBack = `${assetsCdn}/players/dummy.png`;
									}

									return <Player
										key={`playerIndex-${playerIndex}`}
										pointsColor={playerPointsColor}
										pointsBgColor={playerPointsBgColor}
										badgeColor={playerBadgeColor}
										badgeBgColor={playerBadgeBgColor}
										positionIndex={positionIndex + 1}
										modalEnabled={modalEnabled}
										captainId={captainId}
										player={player}
										showPlayerValue={showPlayerValue}
										showPlayerStatsPoints={showPlayerStatsPoints}
										type={playerType}
										onRemove={onRemove}
										showPlayerValueInsteadOfPoints={showPlayerValueInsteadOfPoints}
										// onSwap={onSwap}
										isSwapAble={false}
										onCaptainSelect={onCaptainSelect}
										onPlaceholderClick={onPlaceholderClick}
										// swapPlayerId={swapPlayerId}
										actionLessPlayerIds={actionLessPlayerIds}
										// swappedFrom={swappedFrom}
										{...sportSpecificProps}
									/>
								})
							}
						</div>
					);
				})}
			</TeamStyle>

			<div style={{position: "absolute", top: "0", left: "0", width: "100%", height: "100%", zIndex: 2}}>
				{/* <div style={{position: "absolute", top: "60px", left: "0", width: "20%"}}>
					<a href='https://www.vivabox.be/nl?utm_source=DPGmedia&utm_medium=logo&utm_campaign=BE' target='_blank'><img style={{width: "100%"}} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-vivabox.png" alt="Vivabox"></img></a>
					<a href='http://minerva.be/nl/' target='_blank'><img style={{width: "100%"}} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-minerva.png" alt="Minerva"></img></a>
				</div> */}
				<div style={{position: "absolute", top: "20px", left: "50%", transform: "translate(-50%, -50%)"}}>
					{/* <img style={{width: "20%", padding: "10px"}} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-hln-red.png" alt="HLN"></img> */}
					<a href='http://minerva.be/nl/' target='_blank'><img style={{maxHeight: "50px", marginTop: "10px"}} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-minerva.png" alt="Minerva"></img></a>
					{/* <img style={{width: "20%", padding: "10px"}} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-hln-red.png" alt="HLN"></img> */}
				</div>
				{/* <div style={{position: "absolute", top: "60px", right: "0", width: "20%"}}>
					<a href='https://be.wonderbox.com/nl?utm_source=DPGmedia&utm_medium=logo&utm_campaign=BE' target='_blank'><img style={{width: "100%"}} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-wonderbox.png" alt="Wonderbox"></img></a>
					<a href='http://minerva.be/nl/' target='_blank'><img style={{width: "100%"}} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-minerva.png" alt="Minerva"></img></a>
				</div> */}
			</div>
			</Col></Row>
		);
	}
}

export default Team;