import React, { Component } from 'react';
import { Icon } from 'antd';

import { PlayerModalStyle } from './PlayerModalStyle';
import { Player, Club } from './../../types';
import { ModalProps } from "antd/lib/modal";
import { PlayerBg } from './../Player/PlayerStyle';
import { Row, Col } from './../../components';
import { DeleteButtonSvg, CaptainButtonSvg, SwapButtonSvg, RollBackSvg } from './../../styles/custom-icons';

const ExitIcon = (props: any) => <Icon component={DeleteButtonSvg} {...props} />;
const CaptainIcon = (props: any) => <Icon component={CaptainButtonSvg} {...props} />;
const DeleteIcon = (props: any) => <Icon component={DeleteButtonSvg} {...props} />;
const SwapIcon = (props: any) => <Icon component={SwapButtonSvg} {...props} />;
const UndoIcon = (props: any) => <Icon component={RollBackSvg} {...props} />;


export interface PlayerModalState {
}

export interface PlayerModalProps {
	visible: boolean;
	player: Player;
	club: Club
	shirtCycling?: string;
	onCancel: any;
	onCaptainSelect?: any;
	onRemove?: any;
	onSwap?: any;
	swapPlayerId?: number | null;
	isSwapAble?: any;
}

class PlayerModal extends Component<PlayerModalProps, PlayerModalState> {

	onRemove = (event: any) => {
		this.props.onRemove(this.props.player);
		this.props.onCancel(event);
	};

	onCaptainSelect = (event: any) => {
		this.props.onCaptainSelect(this.props.player);
		this.props.onCancel(event);
	}

	onSwap = (event: any) => {
		this.props.onSwap(this.props.player);
		this.props.onCancel(event);
	}

	render() {
		const { player, shirtCycling, club, onCaptainSelect, onRemove, onSwap, swapPlayerId, isSwapAble } = this.props;

		const title = <div className={'custom-title-container'}>
			<ExitIcon onClick={this.props.onCancel} />
		</div>;

		let totalActions = 0;

		if (onCaptainSelect) {
			totalActions += 1;
		}

		if(onSwap && (player.id !== swapPlayerId)) {
			totalActions += 1;
		}

		if(onSwap && (player.id === swapPlayerId)) {
			totalActions += 1;
		}

		if(onRemove) {
			totalActions += 1;
		}

		const actionColumnSize = 24 / totalActions;

		return (
			<PlayerModalStyle
				title={title}
				closable={false}
				visible={this.props.visible}
				onCancel={this.props.onCancel}
				footer={[]}
			>
				<Row>
					<Col md={8} sm={8} xs={8}>
						<PlayerBg style={{ position: 'inherit', height: 70 }} bg={shirtCycling} />
					</Col>
					<Col md={16} sm={16} xs={16}>
						<p className={'surname'}>{player.surname}</p>
						<p className={'forename'}>{player.forename}</p>
						<p className={'club'}>{club.name}</p>
					</Col>
				</Row>
				<Row>
					{
						onCaptainSelect ?
							<Col md={actionColumnSize} sm={actionColumnSize} xs={actionColumnSize}>
								<div className="action" onClick={this.onCaptainSelect}>
									<CaptainIcon />
									Kopman
								</div>
							</Col> :
							null
					}

					{
						onSwap && (swapPlayerId ? isSwapAble && isSwapAble(player) : false) && (player.id !== swapPlayerId) ?
							<Col md={actionColumnSize} sm={actionColumnSize} xs={actionColumnSize}>
								<div className="action" onClick={this.onSwap}>
									<SwapIcon />
									Wissel
								</div>
							</Col> :
							null
					}

					{
						onSwap && (player.id === swapPlayerId) ?
							<Col md={actionColumnSize} sm={actionColumnSize} xs={actionColumnSize}>
								<div className="action" onClick={this.onSwap}>
									<UndoIcon />
									Maak ongedaan
								</div>
							</Col> :
							null
					}

					{
						onRemove ?
							<Col md={actionColumnSize} sm={actionColumnSize} xs={actionColumnSize}>
								<div className="action" onClick={this.onRemove}>
									<DeleteIcon />
									Verwijder
								</div>
							</Col> :
							null
					}
				</Row>
			</PlayerModalStyle>
		)
	}
}


export default PlayerModal;