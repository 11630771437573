import styled, {keyframes} from "../../styles/styled-components";
import {mediaQueries} from "../../styles/media-queries";

const Block = styled.div`	
	background-color: #292929;
	@media ${mediaQueries.mobileL} {
		padding-top: 15px;
		padding-bottom: 15px;
		
		&.no-margin {
			margin: 0px;
		}
	}
	
	@media ${mediaQueries.mobileS} {
		padding-top: 5px;
		padding-bottom: 5px;
	}
	
	@media (max-width: 425px) {
		margin-left: 5px;
		margin-right: 5px;
	}
	
	margin-left: 15px;
	margin-right: 15px;
	padding-left: 15px;
	padding-right: 15px;
`;

export {
	Block
}