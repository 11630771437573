import React, {Component} from "react";
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import * as userActions from '../../actions/user'
import * as playersActions from '../../actions/players'
import {StoreState, ApplicationState, PlayersState, ClubsState, Player} from './../../types';
import PlayerPortrait from '../../components/PlayerPortrait/PlayerPortrait';
import {ContainerStyle, SelectGroupStyle, BasicTableStyle} from './../../components/PlayerList/PlayerListStyle';
import Link from './../../components/UI/Link/Link';
import {Icon} from 'antd';

import {Element, scroller} from 'react-scroll'

import {
	Layout,
	Title,
	TitleType,
	PlayerList,
	PlayerType,
	Block,
	Button,
	Row,
	Col
} from './../../components';

export interface Props {
	application: ApplicationState;
	players: PlayersState;
	clubs: ClubsState;
}

export interface State {
	selectedPlayer: any,
	selectedPlayerStats: any
}


class Stats extends Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			selectedPlayer: props.players.data[0] || null,
			selectedPlayerStats: []
		};
	};

	componentDidMount() {
		if(this.props.players.data.length) {
			this.getPlayerDetails(this.props.players.data[0]);
		}
	}

	getPlayerDetails(firstPlayer: Player) {
		playersActions.getPlayerDetails(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId,
			firstPlayer.id
		)
			.then((result: any) => {
				this.setState({selectedPlayer: firstPlayer, selectedPlayerStats: result.stats || []});
			});
	}

	componentDidUpdate(prevProps: Props) {
		if ((this.props.players.data.length !== prevProps.players.data.length) && this.props.players.data.length) {
			this.getPlayerDetails(this.props.players.data[0]);
		}
	}

	setPlayer = (player: Player) => {
		playersActions.getPlayerDetails(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId,
			player.id
		)
			.then((result: any) => {
				this.setState({selectedPlayer: player, selectedPlayerStats: result.stats || []});

				scroller.scrollTo('player-stats', {
					duration: 1000,
					delay: 100,
					smooth: true,
					offset: 0
				})
			});
	};

	render() {
		let selectedPlayerClub = null;

		if (this.state.selectedPlayer && this.props.clubs.data) {
			selectedPlayerClub = this.props.clubs.data.find((club: any) => club.id === this.state.selectedPlayer.clubId);
		}

		const columns = [{
			key: 'weekId',
			title: 'Speeldag',
			dataIndex: 'weekId',
			width: '20%',
			render: (text: string, record: any) => {
				return <b>{record.stat.weekId}</b>
			}
		}, {
			key: 'race',
			title: 'Race',
			width: '50%',
			dataIndex: 'race',
			render: (text: string, record: any) => {
				return <Link to={`/race/${record.match.id}`}>{record.match.feedUrl}</Link>
			}
		}, {
			key: 'Punten',
			width: '15%',
			title: 'Punten',
			dataIndex: 'points',
			render: (text: string, record: any) => {
				return <b>{record.stat.points}</b>
			}
		}
		];

		console.log(this.state.selectedPlayer);
		console.log(`${this.props.application.competition.assetsCdn}/jerseys/formulaone/${this.state.selectedPlayer && this.state.selectedPlayer.id}.png`)

		const selectedPlayerIcon = `${this.props.application.competition.assetsCdn}/jerseys/formulaone/${this.state.selectedPlayer && this.state.selectedPlayer.id}.png`;
		return (
			<Layout>
				<Row>
					<Col md={12} sm={12} xs={24}>
						<Block>
							<Row>
								<Title type={TitleType.h2}>Alle Coureurs</Title>
								<PlayerList
									clubs={this.props.clubs.data}
									isLoading={this.props.players.isFetching}
									hidePositions={false}
									assetsCdn={this.props.application.competition.assetsCdn}
									playerType={PlayerType.FormulaOne}
									data={this.props.players.data}
									onSelect={this.setPlayer}
									action
									showHeader={false}
									size={10}
								/>
							</Row>
						</Block>
						<Block>
							<Row justify="center">
								<Col md={12} sm={12} xs={24}>
									<Block>
										<a
											href={`https://goudenspelen.s3.eu-west-1.amazonaws.com/files/spelers_${this.props.application.competition.competitionFeed}.xlsx`}>
											<Button
												style={{width: '100%', maxWidth: '630px'}}
												size="large">
												<Icon type="file-excel" theme="filled"/>
												Coureurlijst (Excel)
											</Button>
										</a>
									</Block>
								</Col>
							</Row>
						</Block>
					</Col>

					<Col md={12} sm={12} xs={24}>
						{
							(this.state.selectedPlayer &&
								<Block>
									<Element name="player-stats">
										<Row>
											<Title type={TitleType.h2}>Stats</Title>
											<Col md={24} sm={25} xs={24}>
												<Row>
													<PlayerPortrait
														icon={selectedPlayerIcon}
														type={PlayerType.FormulaOne}
														style={{height: '125px', width: '125px', margin: '0 auto'}}
														size={'120px'}
													/>
												</Row>

												<Row>
													<div style={{textAlign: 'center'}}>
														<Title type={TitleType.h4}>{this.state.selectedPlayer.name}</Title>
														<p>{selectedPlayerClub && selectedPlayerClub.name}</p>
													</div>
												</Row>

												<Row>
													<BasicTableStyle
														columns={columns}
														dataSource={this.state.selectedPlayerStats}
														showHeader={true}
														locale={{emptyText: 'Nog geen statistieken beschikbaar.'}}
														loading={false}
														pagination={{pageSize: 10}}
														rowKey={(record: any, index: number) => `record-${index + 1}`}
														rowClassName={(record: object, index: number) => index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'}
													/>
												</Row>
											</Col>
										</Row>
									</Element>
								</Block>) || null
						}
					</Col>
				</Row>
			</Layout>
		);
	}
}

export function mapDispatchToProps(dispatch: Dispatch<userActions.UserAction>) {
	return {}
}

export function mapStateToProps({application, players, clubs}: StoreState.All) {
	return {
		application,
		players,
		clubs
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Stats);