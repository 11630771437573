import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Player, AbstractTeamType, UserState } from './../../types';
import {
	Title, TitleType, Team, Substitutes, PlayerType, Row, Col, Block,
	Select, Button, AbstractTeam, MatchDaySelector, Calendar, LeaguesList
} from './../../components';
import { Icon } from 'antd';
import { theme } from '../../styles/theme-goudenspelen';

const teamBackground = 'https://goudenspelen.s3-eu-west-1.amazonaws.com/files/goudenspelen-formulaone-lineup.png';
import { startingListToPositionsList } from './../../lib/helpers';
import { pick } from 'lodash';
import * as teamsActions from '../../actions/teams'
import {SaveLineupButton} from './TeamStyles';

export interface Props {
	match: any;
	user: UserState;
}

export interface State {
	notFound: boolean;
	navigateToLeagues: boolean;
	selectedLeagueId: number | null;
}

class TeamContainer extends Component<AbstractTeamType & Props, State> {
	constructor(props: AbstractTeamType & Props) {
		super(props);

		this.state = {
			notFound: false,
			navigateToLeagues: false,
			selectedLeagueId: null
		}
	};

	componentDidMount() {
		if (this.props.visibleWeekId) {
			this.getTeamInfo(this.props.visibleWeekId);
		}
	}

	componentDidUpdate(prevProps: AbstractTeamType & Props) {
		if (
			this.props.visibleWeekId && (
				(this.props.visibleWeekId !== prevProps.visibleWeekId) ||
				(this.props.match.params.id !== prevProps.match.params.id)
			)
		) {
			this.getTeamInfo(this.props.visibleWeekId);
		}
	}

	getTeamInfo = (weekId: number) => {
		const teamId = this.props.match.params.id;
		const playerProps =
			['id', 'name', 'short', 'positionId', 'clubId', 'value', 'ban', 'injury', 'form', 'forename', 'surname'];
		const selectionProps: any[] = [];
		Promise.all([teamsActions.get(teamId, weekId)])
			.then(([result]) => {
				const starting = result.players
					.filter((player: any) => player.selection.positionId === 1)
					.map((player: any) => Object.assign({ inStarting: true }, pick(player, playerProps), pick(player.selection, selectionProps)));
				const bench = result.players
					.filter((player: any) => player.selection.positionId === 0)
					.map((player: any) => Object.assign({ inStarting: false }, pick(player, playerProps), pick(player.selection, selectionProps)));
				const team = result.team;
				const teamName = result.team.name;

				// const captainId = result.players
				// 	.find((player: any) => player.selection.captain === 1)
				// 	.id;

				const budget = result.players
					.reduce((acc: any, player: any) => acc - player.value, this.props.application.competition.budget);

				this.props.loadAllMatches();
				this.props.initTeamState(starting, bench, team, teamName, budget);
			})
			.catch(error => {
				this.setState({ notFound: true });
			})
	};

	render() {
		const {
			application, starting, bench, //captainId, 
			matches, user, visibleWeekId, initializedExternally,
			leagues
		} = this.props;
		const startingByPositions = startingListToPositionsList(starting, application.competition.lineupPositionRows);
		const startingPicked = starting
			.filter(player => !!player);

		const teamId = parseInt(this.props.match.params.id);

		const team = this.props.user &&
			this.props.user.teams &&
			this.props.user.teams.find((team: any, index: number) => team.id === teamId);


		const notTeamOwner = team && team.userId && user.data && (team.userId !== user.data.id);

		const leaguesWithNumbers = leagues
			.map((league, index) => Object.assign({}, league, { recordNumber: index + 1 }));

		const teamActionButtonStyle = { marginTop: '15px', width: '100%', maxWidth: '630px' };
		const deadlineWeek = matches.info && matches.info.deadlineWeek;

		const match = matches.data.filter(match => match.weekId === visibleWeekId);

		const userRegisterInfo = (this.props.user && this.props.user.data && this.props.user.data.registerInfo) || '';
		return (
			<React.Fragment>
				{team && notTeamOwner && <Redirect
					to={{ pathname: `/transfers/${team.id}` }}
				/>}
				{
					(visibleWeekId && deadlineWeek &&
						<Block>
							<MatchDaySelector day={visibleWeekId}
								min={deadlineWeek}
								match={match}
								onNext={(e: any) => this.props.onDayChange('next')}
								onPrev={(e: any) => this.props.onDayChange('prev')} />
						</Block>)
					|| null
				}

				{
					(team && initializedExternally && <Row>
						
						<Col lg={24} md={24} sm={24} xs={24}>
							<Block>

								{ visibleWeekId && (<SaveLineupButton style={{margin: '0'}}> 
										<Button
											type="primary"
											style={{margin: '0 0 0px 0'}}
											onClick={(e: any) => this.props.onTeamSelectionsUpdate(team.id, visibleWeekId)}
											size="large">
											<Icon type="save" theme="filled" />
											Bewaar team
										</Button>
									</SaveLineupButton>) || null
								}
								
								<Team
									widthRatio={15}
									heightRatio={20}
									clubs={this.props.clubs.data}
									bg={teamBackground}
									// captainId={captainId}
									modalEnabled={false}
									selection={startingByPositions}
									assetsCdn={application.competition.assetsCdn}
									playerType={PlayerType.FormulaOne}
									onCaptainSelect={this.props.onCaptainSelect}
									onSwap={this.props.onPlayerSwap}
									swapPlayerId={this.props.swapPlayerId}
									swappedFrom={this.props.swappedFrom}
									playerBadgeColor={theme.primaryColor}
									playerBadgeBgColor={theme.primaryColor}
									playerPointsColor="#fff"
									playerPointsBgColor="#84FF00"
									isSwapAble={this.props.isSwapAble} />
								{	(this.props.application.competition.benchSize) ?
									<Substitutes
										selection={bench}
										clubs={this.props.clubs.data}
										title="De bus"
										bgColor="#F0F3F8"
										modalEnabled={true}
										playerType={PlayerType.FormulaOne}
										assetsCdn={application.competition.assetsCdn}
										onSwap={this.props.onPlayerSwap}
										swapPlayerId={this.props.swapPlayerId}
										swappedFrom={this.props.swappedFrom}
										playerBadgeColor="#000"
										playerBadgeBgColor={theme.primaryColor}
										playerPointsColor="#fff"
										playerPointsBgColor="#292929"
										isSwapAble={this.props.isSwapAble}
									/>
									: null
								}
							</Block>
						</Col>
					</Row>) || null
				}
			</React.Fragment>
		);
	}
}

const WrappedComponent = AbstractTeam(TeamContainer);


export default WrappedComponent;