import {
	loadTeams,
	teamsFetchedWithSuccess,
	teamsFetchFailed,
	UserAction,
	teamNameChanged
} from './user';

import { Dispatch } from "redux";
import http from './../lib/http';
import { notification } from 'antd';

export function add(competitionFeed: string, seasonId: number, teamName: string, starting: number[],
	bench: number[], captainId: number) {
	return function (dispatch: Dispatch<UserAction>) {
		dispatch(loadTeams());

		return http.post('team/add', { competitionFeed, seasonId, teamName, starting, bench, captainId })
			.then(createdTeam => {
				dispatch(teamsFetchedWithSuccess([createdTeam], true));
				notification.success({ message: 'Ploeg aangemaakt.' });
			})
			.catch(error => {
				dispatch(teamsFetchFailed());
				notification.warning({ message: 'Je ploeg kan niet bewaard worden. Probeer later opnieuw. Contacteer ons als dit probleem blijft duren.' });
			})
	};
}

export function copy(teamId: number) {
	return function (dispatch: Dispatch<UserAction>) {
		return http.post(`team/${teamId}/copy`, {})
			.then(result => {
				dispatch(teamsFetchedWithSuccess([result.team], true));
				notification.success({ message: 'Team gekopieerd.' });
			})
			.catch(error => {
				dispatch(teamsFetchFailed());
				notification.warning({ message: 'Team could not be copied.' });
			})
	};
}

export function drop(teamId: number) {
	return function (dispatch: Dispatch<UserAction>) {
		return http.delete(`team/${teamId}`, {})
			.then(result => {
				dispatch(teamsFetchedWithSuccess(result.teams));
				notification.success({ message: 'Team verwijderd.' });
			})
			.catch(error => {
				dispatch(teamsFetchFailed());
				notification.warning({ message: 'Team could not be copied.' });
			})
	};
}

export function get(teamId: number, weekId?: number, transfersWithMeta?: boolean) {
	const params: any = {
		_t: new Date().getTime()
	};
	if (weekId) {
		params.weekId = weekId;
	}

	if(transfersWithMeta) {
		params.transfersWithMeta = 1;
	}
	return http.get(`team/${teamId}`, params);
}

export function points(teamId: number, weekId?: number) {
	const params: any = {};
	return http.get(`team/${teamId}/points/${weekId}`, params);
}

export function updateTeamName(teamId: number, teamName: string) {
	return function (dispatch: Dispatch<UserAction>) {
		return http.post(`team/${teamId}/name`, { teamName })
			.then(() => {
				notification.success({ message: 'Ploegnaam aangepast.' });
				dispatch(teamNameChanged(teamId, teamName));
				return true;
			})
			.catch(() => {
				notification.warning({ message: 'Ploegnaam kan niet worden aangepast.' });
			})
	};
}

export function edit(teamId: number, competitionFeed: string, seasonId: number, teamName: string, starting: number[],
	bench: number[], captainId: number | undefined) {
	return http.post(`team/${teamId}`, {
		competitionFeed,
		seasonId,
		teamName,
		starting,
		bench,
		captainId
	})
		.then(() => {
			notification.success({ message: 'Ploeg aangepast.' });
		})
		.catch(() => {
			notification.warning({ message: 'Ploeg kan niet worden aangepast.' });
		});
}

export function editWeekSelections(teamId: number, competitionFeed: string, seasonId: number, teamName: string,
	starting: number[], bench: number[], captainId: number | undefined, weekId: number) {
	return http.post(`team/${teamId}/selections`, {
		competitionFeed,
		seasonId,
		teamName,
		starting,
		bench,
		captainId,
		weekId
	})
		.then(() => {
			notification.success({ message: 'Ploeg aangepast.' });
		})
		.catch(() => {
			notification.warning({ message: 'Ploeg kan niet worden aangepast.' });
		});
}

export function submitTransfers(teamId: number, transfers: any[]) {
	return http.post(`team/transfers/${teamId}`, { transfers })
		.then(() => {
			notification.success({ message: 'Transfer bewaard.' });
		})
		.catch(() => {
			notification.warning({ message: 'Transfers kunnen niet bewaard worden.' });
		});
}

export function resetTransfers(teamId: number) {
	return http.post(`team/transfers/${teamId}/reset`, {})
		.then(() => {
			notification.success({ message: 'Transfers gewist.' });
		})
		.catch(() => {
			notification.warning({ message: 'Could not reset transfers.' });
		});
}

export function getRankings(competitionFeed: string, rankingType: string, pageRecords: number, pageNumber: number, name?: string, weekId?: number, rankingTypeId?: number | null) {
	return http.get(`rankings/${rankingType}`, { pageRecords, pageNumber, name, weekId, competitionFeed, rankingTypeId });
}

export function getSubscriptions(competitionFeed: string, seasonId: number) {
	return http.get(`subscriptions`, { competitionFeed, seasonId });
}

export function createPayment(coupons: string[], competitionFeed: string, seasonId: number, teamIds: number[], redirectURL: string, paymentMethod: string) {
	return http.post(`payments/create`, { coupons, competitionFeed, seasonId, teamIds, redirectURL, paymentMethod });
}

export function cancelPayment(paymentId: number) {
	return http.post(`payments/cancel`, { paymentId });
}

export function validateCoupon(coupon: string, competitionFeed: string) {
	return http.post(`payments/coupon`, { coupon, competitionFeed });
}

